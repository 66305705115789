<script>
import FormButton from '@/components/FormButton'

export default {
  name: 'UserSecurity2FA',
  components: {
    FormButton
  },
  filters: {
    formatDatetime(datetime, { dateStyle = 'long', timeStyle = 'short' } = {}) {
      try {
        const date = new Date(datetime)
        const options = { dateStyle, timeStyle }
        return new Intl.DateTimeFormat('en-US', options).format(date)
      } catch (_) {
        return datetime
      }
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    here() {
      return `${this.root}#/security/2fa`
    },
    updateUrl() {
      return `${this.root}/`
    },
    postponeMandatoryOtpUrl() {
      return `${this.root}/postpone_mandatory_otp`
    },
    canPostponeMandatoryOtp() {
      return !!this.userPermissions.postpone_mandatory_otp
    }
  },
  methods: {
    generateUrl(path) {
      return this.root + path
    },
    hasPermission(name) {
      return this.userPermissions[name]
    },
    statusIcon(status) {
      return status ? 'check' : 'times'
    },
    statusClassList(status) {
      return {
        'text-success': status,
        'text-danger': !status
      }
    }
  }
}
</script>

<template>
  <div class="user-security-2fa">
    <h3>Two-Factor Authentication (2FA)</h3>
    <div class="row">
      <div class="mb-5 col-12 col-lg-7">
        <p class="text-muted">
          2FA adds an additional layer of protection to your account by requiring more than just a password to sign in.
          To keep access to your ICIJ account, please activate at least one 2FA method.
        </p>
        <b-card no-body header="Two-Factor methods" header-class="font-weight-bold" class="mb-3">
          <b-list-group flush>
            <b-list-group-item class="d-flex align-items-center">
              <div>
                <fa
                  class="mr-1"
                  fixed-width
                  :icon="statusIcon(user.has_otp_enabled)"
                  :class="statusClassList(user.has_otp_enabled)"
                />
                Authenticator app
              </div>
              <div class="ml-auto">
                <span v-if="user.has_otp_enabled" class="text-muted small mr-1"> Configured </span>
                <b-button
                  v-if="user.has_otp_enabled && hasPermission('deactivate_two_factor')"
                  :href="generateUrl('/two_factor')"
                  variant="outline-primary"
                  size="sm"
                >
                  Disable
                </b-button>
                <b-button
                  v-if="!user.has_otp_enabled && hasPermission('activate_two_factor')"
                  :href="generateUrl('/two_factor')"
                  variant="outline-primary"
                  size="sm"
                >
                  Enable
                </b-button>
              </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex align-items-center">
              <div>
                <fa
                  class="mr-1"
                  fixed-width
                  :icon="statusIcon(user.has_webauthn_enabled)"
                  :class="statusClassList(user.has_webauthn_enabled)"
                />
                Physical keys
              </div>
              <div class="ml-auto">
                <span v-if="user.has_webauthn_enabled" class="text-muted small mr-1">
                  <template v-if="user.webauthn_credentials === 1"> 1 key </template>
                  <template v-else> {{ user.webauthn_credentials }} keys </template>
                </span>
                <b-button
                  v-if="hasPermission('manage_physical_keys')"
                  href="#/security/physical-keys"
                  size="sm"
                  variant="outline-primary"
                >
                  Manage
                </b-button>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="!user.two_factor_enabled" class="d-flex align-items-center">
              <div>
                <fa class="mr-1 text-danger" fixed-width icon="clock" />
                A 2FA method must be configured on this account before
                <strong>{{ user.mandatory_otp_at | formatDatetime }}</strong
                >.
              </div>
              <div class="ml-3">
                <form-button
                  v-if="canPostponeMandatoryOtp"
                  :url="postponeMandatoryOtpUrl"
                  :redirect="here"
                  size="sm"
                  variant="outline-danger"
                >
                  Postpone
                </form-button>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </div>
      <div class="col text-center">
        <img src="@/assets/images/devise-2fa.svg" class="mw-100" width="250" alt="" />
      </div>
    </div>
  </div>
</template>
