<template>
  <section
    class="onboarding-step-create-password d-flex flex-lg-row flex-column justify-content-between align-items-center"
  >
    <div class="col-12 col-md-9">
      <onboarding-title :step="1">Create your password</onboarding-title>
      <div class="d-flex">
        <div class="d-none d-xl-flex col-xl-3 align-items-end">
          <img
            src="@/assets/onboarding/support-notes.svg"
            class="onboarding-step-create-password__illustration img-fluid"
          />
        </div>
        <xemx-form class="d-flex flex-column col-xl-8 mb-4" method="POST" :action="redirectUrl">
          <div class="col-11">
            <b-form-group
              label="Password"
              label-for="password"
              class="onboarding-step-create-password__password-group font-weight-bold"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    variant="link"
                    class="onboarding-step-create-password__toggler"
                    @click="showPassword = !showPassword"
                  >
                    <fa fixed-width :icon="togglerIcon" />
                  </b-button>
                </b-input-group-prepend>
                <b-form-input v-model="password" required :state="isPasswordValid" :type="inputType" name="password">
                </b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Confirm your password"
              label-for="passwordConfirmation"
              class="onboarding-step-create-password__confirm-password-group font-weight-bold"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    variant="link"
                    class="onboarding-step-create-password__toggler"
                    @click="showPassword = !showPassword"
                  >
                    <fa fixed-width :icon="togglerIcon" />
                  </b-button>
                </b-input-group-prepend>
                <b-form-input
                  v-model="passwordConfirmation"
                  :type="inputType"
                  required
                  name="password_confirmation"
                  :state="isPasswordConfirmationValid"
              /></b-input-group>
            </b-form-group>
            <div>
              <p>Password should contain at least:</p>
              <ul class="list-unstyled pl-3">
                <li
                  v-for="(criterium, i) in criteriaDescription"
                  :key="i"
                  :class="{
                    [criterium.className]: true
                  }"
                  class="onboarding-step-create-password__validation"
                >
                  <fa
                    :icon="criterium.status ? 'circle-check' : 'circle-xmark'"
                    :class="{
                      'text-success': criterium.status,
                      [`${criterium.className}__icon`]: true
                    }"
                    fixed-width
                    class="mr-1"
                  />{{ criterium.text }}
                </li>
              </ul>
            </div>
          </div>
          <footer class="d-flex justify-content-end">
            <onboarding-button class="onboarding-step-create-password__submit" type="submit" :disabled="disableNext"
              >Next</onboarding-button
            >
          </footer>
        </xemx-form>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <onboarding-support />
    </div>
  </section>
</template>
<script>
import OnboardingTitle from '@/components/onboarding/OnboardingTitle'
import OnboardingSupport from '@/components/onboarding/OnboardingSupport'
import OnboardingButton from '@/components/onboarding/OnboardingButton'
import XemxForm from '@/components/XemxForm'

const CRITERIA_NAME = Object.freeze({
  LENGTH: 'length',
  UPPERCASE: 'uppercase',
  LOWERCASE: 'lowercase',
  NUMBER: 'number',
  MATCH: 'match'
})

export default {
  name: 'OnboardingStepCreatePassword',
  components: {
    XemxForm,
    OnboardingTitle,
    OnboardingSupport,
    OnboardingButton
  },
  data() {
    return {
      password: null,
      passwordConfirmation: null,
      showPassword: false
    }
  },
  computed: {
    criteriaDescription() {
      return [
        {
          className: `onboarding-step-create-password__validation__${CRITERIA_NAME.LENGTH}`,
          status: this.isValidLength,
          text: '12 characters'
        },
        {
          className: `onboarding-step-create-password__validation__${CRITERIA_NAME.UPPERCASE}`,
          status: this.hasOneUppercase,
          text: '1 uppercase letter'
        },
        {
          className: `onboarding-step-create-password__validation__${CRITERIA_NAME.LOWERCASE}`,
          status: this.hasOneLowercase,
          text: '1 lowercase letter'
        },
        {
          className: `onboarding-step-create-password__validation__${CRITERIA_NAME.NUMBER}`,
          status: this.hasOneNumber,
          text: '1 number'
        },
        {
          className: `onboarding-step-create-password__validation__${CRITERIA_NAME.MATCH}`,
          status: this.isPasswordConfirmationValid,
          text: 'Password confirmation matches password'
        }
      ]
    },
    disableNext() {
      return this.isPasswordValid !== true || this.isPasswordConfirmationValid !== true
    },
    hasOneNumber() {
      if (!this.password) {
        return false
      }
      const atLeastOneNumber = /(?=.*?[0-9])/
      return atLeastOneNumber.test(this.password)
    },
    hasOneUppercase() {
      if (!this.password) {
        return false
      }
      const atLeastOneUppercase = /(?=.*?[A-Z])/
      return atLeastOneUppercase.test(this.password)
    },
    hasOneLowercase() {
      if (!this.password) {
        return false
      }
      const atLeastOneLowercase = /(?=.*?[a-z])/
      return atLeastOneLowercase.test(this.password)
    },
    isValidLength() {
      if (!this.password) {
        return false
      }
      return this.password.length >= 12
    },
    isPasswordValid() {
      if (this.password?.length) {
        return this.isValidLength && this.hasOneNumber && this.hasOneUppercase && this.hasOneLowercase
      }
      return null
    },
    isPasswordConfirmationValid() {
      if (!this.passwordConfirmation?.length) {
        return null
      }
      return this.password === this.passwordConfirmation
    },
    redirectUrl() {
      return `/onboarding/update-password`
    },
    inputType() {
      return this.showPassword ? 'input' : 'password'
    },
    togglerIcon() {
      return this.showPassword ? 'eye-slash' : 'eye'
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../utils/variables';

.onboarding-step-create-password {
  &__illustration {
    margin-bottom: $spacer * -1.5;
  }

  &__toggler {
    background: $input-disabled-bg;
    border: $input-border-width solid $input-border-color;
    border-right: 0;
  }
}
</style>
