<template>
  <section class="onboarding-step-enable-2fa d-flex flex-column flex-lg-row justify-content-between">
    <div class="onboarding-step-enable-2fa__content d-flex flex-column col-lg-9 col-12">
      <onboarding-title :step="4">
        Enable two-factor authentication (<abbr title="two-factor authentication">2FA</abbr>) on your phone
      </onboarding-title>
      <user-security-2fa-form
        v-if="isWaitingWithoutError"
        no-header
        :display-password-step="false"
        :user-id="userId"
        :reset-otp-token="qrCodeDetails.resetOtpToken"
        :unconfirmed-otp-secret="qrCodeDetails.unconfirmedOtpSecret"
        :unconfirmed-otp-secret-qr-base64="qrCodeDetails.unconfirmedOtpSecretQrBase64"
        url="/onboarding/create-2fa"
        class="onboarding-step-enable-2fa__content__form"
      >
        <template #description>
          <onboarding-info class="my-4">
            <template #header> What is a <abbr title="two-factor authentication">2FA</abbr>? </template>
            <template #content>
              <ul>
                <li>
                  The
                  <abbr title="two-factor authentication">2FA</abbr> (two-factor authentication) adds another layer of
                  security
                </li>
                <li>It's based on a temporary code, valid for 30 seconds</li>
                <li>
                  The code is generated by a
                  <abbr title="two-factor authentication">2FA</abbr> application on your phone
                </li>
                <li>It will be asked each time you log in to ICIJ's services</li>
              </ul>
            </template>
          </onboarding-info>
        </template>
      </user-security-2fa-form>
      <p v-if="error" class="onboarding-step-enable-2fa__content__error-message text-danger mt-3 small">
        {{ error }}
      </p>
    </div>
    <div class="col-6 col-lg-3 align-self-center">
      <onboarding-support />
      <img
        src="@/assets/onboarding/person-with-mobile-phone.svg"
        alt="Person standing with a mobile phone"
        class="img-fluid my-5"
      />
    </div>
  </section>
</template>
<script>
import { camelCase } from 'lodash'
import { waitFor } from 'vue-wait'

import OnboardingInfo from '@/components/onboarding/OnboardingInfo'
import OnboardingTitle from '@/components/onboarding/OnboardingTitle'
import OnboardingSupport from '@/components/onboarding/OnboardingSupport'
import UserSecurity2faForm from '@/components/UserSecurity2FAForm'

export default {
  name: 'OnboardingStepEnable2FA',
  components: {
    OnboardingInfo,
    OnboardingTitle,
    OnboardingSupport,
    UserSecurity2faForm
  },
  inject: ['api'],
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      error: null,
      qrCodeDetails: {
        resetOtpToken: '',
        unconfirmedOtpSecret: '',
        unconfirmedOtpSecretQrBase64: ''
      }
    }
  },
  computed: {
    isWaitingWithoutError() {
      return !this.$wait.waiting('qrCode') && !this.error
    }
  },
  created() {
    this.setup()
  },
  methods: {
    setup: waitFor('qrCode', async function () {
      this.error = null
      try {
        const details = await this.api.onboardingQrCodeDetails()
        this.qrCodeDetails = this.cameliseKeys(details)
      } catch (error) {
        this.error = error?.message ?? error
      }
    }),
    cameliseKeys(obj) {
      Object.keys(obj).forEach((key) => {
        const tmp = obj[key]
        delete obj[key]
        obj[camelCase(key)] = tmp
      })
      return obj
    }
  }
}
</script>
