<script>
export default {
  name: 'UserSecurityActivities',
  filters: {
    formatDatetime(datetime, { dateStyle = 'long', timeStyle = 'short' } = {}) {
      const date = new Date(datetime)
      const options = { dateStyle, timeStyle }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      logs: [],
      busy: false
    }
  },
  async created() {
    this.busy = true
    this.logs = await this.fetchActivityLog()
    this.busy = false
  },
  methods: {
    async fetchActivityLog() {
      const { activity_log: activityLog } = await this.api.getActivityLog(this.user.id)
      return activityLog ?? []
    }
  }
}
</script>

<template>
  <div class="user-security-activities">
    <h3>Activities</h3>
    <p class="text-muted">Track on this page all activties performed by or onto this ICIJ account.</p>
    <b-overlay :show="busy">
      <b-list-group v-if="logs.length !== 0" class="mb-2">
        <b-list-group-item v-for="(log, index) in logs" :key="index">
          <div class="row">
            <div class="col flex-grow-1 font-weight-bold">
              {{ log.description }}
              <div class="text-muted small">Performed by {{ log.user }}</div>
            </div>
            <div class="col text-right small">
              {{ log.created_at | formatDatetime({ dateStyle: 'short' }) }}
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
      <p v-else-if="!busy" class="card card-body text-center text-muted small">No activity logs for this user yet.</p>
    </b-overlay>
  </div>
</template>
