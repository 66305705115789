<template>
  <section class="onboarding-create-pgp-key d-flex flex-column col-9 col-lg-12 mx-auto">
    <onboarding-create-pgp-accordion :user-id="userId" :email="email" class="col-lg-10 mx-auto" />

    <footer>
      <b-btn
        class="onboarding-create-pgp-key__back text-dark font-weight-bold pl-0"
        variant="link"
        size="lg"
        @click="handleBackClicked"
        ><fa icon="circle-left" fixed-width class="mr-1" />Back</b-btn
      >
    </footer>
  </section>
</template>
<script>
import OnboardingCreatePgpAccordion from '@/components/onboarding/OnboardingCreatePgpKeyAccordion'

export default {
  name: 'OnboardingStepPgp',
  components: {
    OnboardingCreatePgpAccordion
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  methods: {
    handleBackClicked() {
      this.$emit('back-clicked')
    }
  }
}
</script>
