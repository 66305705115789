<script>
import { AccordionStep, AccordionWrapper } from '@icij/murmur'

import Totp from '@/utils/totp'
import XemxForm from '@/components/XemxForm'

const TWO_FA_STEPS = Object.freeze({
  PASSWORD: Symbol('PASSWORD'),
  INSTALL: Symbol('INSTALL'),
  SCAN: Symbol('SCAN')
})

export default {
  name: 'UserSecurity2FAForm',
  components: {
    XemxForm,
    AccordionWrapper,
    AccordionStep
  },
  props: {
    userId: {
      type: [String, Number],
      required: true
    },
    resetOtpToken: {
      type: String,
      default: ''
    },
    unconfirmedOtpSecret: {
      type: String,
      default: ''
    },
    unconfirmedOtpSecretQrBase64: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    backUrl: {
      type: String,
      default: '/'
    },
    displayPasswordStep: {
      type: Boolean,
      default: true
    },
    noHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      otpTimestampId: 0,
      otpTimestamp: null,
      otpAttempt: '',
      otpGenerator: null,
      password: null,
      twoFaSteps: TWO_FA_STEPS,
      step: this.displayPasswordStep ? TWO_FA_STEPS.PASSWORD : TWO_FA_STEPS.INSTALL
    }
  },
  computed: {
    hasOtpAttempt() {
      return this.otpAttempt !== ''
    },
    otpAttemptState() {
      return this.hasOtpAttempt ? this.otpAttemptValid : null
    },
    otpAttemptValid() {
      return this.otpAttempt === this.otpCode
    },
    otpCode() {
      return this.otpTimestamp ? this.otpGenerator.getOtpCode() : null
    },
    steps() {
      return this.displayPasswordStep ? Object.values(this.twoFaSteps) : [this.twoFaSteps.INSTALL, this.twoFaSteps.SCAN]
    },
    unconfirmedOtpSecretWithSpaces() {
      return this.unconfirmedOtpSecret.replace(/(\w{4})?/gi, (p0, p1) => {
        if (p1) {
          return `<span class="px-1">${p1}</span>`
        }
        return ''
      })
    }
  },
  async created() {
    // Create TOTP instance
    this.otpGenerator = Object.freeze(await Totp.bootstrap(this.unconfirmedOtpSecret))
    // Set a new "otp timestamp" every second to make
    // the "otpCode" computed value reactive
    this.otpTimestampId = setInterval(() => this.otpTimestamp++, 1e3)
  },
  beforeDestroy() {
    clearInterval(this.otpTimestampId)
  }
}
</script>

<template>
  <div class="user-security-2fa-form mb-3">
    <slot name="header">
      <div v-if="!noHeader" class="page-header">
        <h1>Enable two-factor authentication</h1>
      </div>
    </slot>
    <div class="mw-sm mx-auto user-security-2fa-form__content">
      <slot name="description">
        <p>
          Two-factor authentication (2FA) adds another layer of security to your account so if your password is
          compromised or stolen, only you can log in.
        </p>
      </slot>
      <xemx-form method="POST" :action="url">
        <input type="hidden" name="utf8" value="✓" />
        <input type="hidden" name="user[reset_otp_token]" :value="resetOtpToken" />
        <input type="hidden" name="user[unconfirmed_otp_secret]" :value="unconfirmedOtpSecret" />
        <accordion-wrapper v-model="step" :steps="steps">
          <accordion-step v-if="displayPasswordStep" :step="twoFaSteps.PASSWORD" title="Enter your password">
            <template #content="{ nextStep }">
              <div class="card-body p-0">
                <b-form-group
                  label="Enter your password"
                  label-class="sr-only"
                  description="This is just to confirm your identity."
                  required
                >
                  <b-form-input
                    v-model="password"
                    type="password"
                    name="user[password]"
                    required
                    @keyup.enter="password ? nextStep() : null"
                  />
                </b-form-group>
              </div>
            </template>
            <template #previousStepButton>
              <b-button type="button" :href="backUrl" variant="link"> Back </b-button>
            </template>
          </accordion-step>

          <accordion-step :step="twoFaSteps.INSTALL" title="Install 2FA app">
            <template #content>
              <div class="col">
                <p>
                  To be able to use 2FA, you must install an app to generate temporary codes on your phone. We recommend
                  using an app such as:
                </p>
                <ul>
                  <li>
                    <strong>Google Authenticator</strong><br />
                    <span class="small">
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        >Android</a
                      >
                      |
                      <a target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                        >iPhone</a
                      >
                    </span>
                  </li>
                  <li>
                    Authy<br />
                    <span class="small">
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.authy.authy"
                        >Android</a
                      >
                      |
                      <a target="_blank" href="https://apps.apple.com/us/app/twilio-authy/id494168017">iPhone</a>
                    </span>
                  </li>
                  <li>
                    Microsoft Authenticator<br />
                    <span class="small">
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                        >Android</a
                      >
                      |
                      <a target="_blank" href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                        >iPhone</a
                      >
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-4 d-none d-md-block">
                <img src="@/assets/images/phone.svg" class="mw-100" width="150" alt="" />
              </div>
            </template>
          </accordion-step>

          <accordion-step :step="twoFaSteps.SCAN" title="Scan 2FA authentication code">
            <template #content>
              <p>
                Scan the image above with the Google Authenticator on your phone. If you can't use the QR, enter the
                text code instead.
              </p>
              <figure class="figure mx-auto text-center d-block">
                <img
                  :src="unconfirmedOtpSecretQrBase64"
                  width="200px"
                  height="auto"
                  class="figure-img img-fluid rounded border"
                  alt="Unconfirmed Otp Secret QR code"
                />
                <figcaption class="figure-caption text-nowrap small">
                  <kbd v-html="unconfirmedOtpSecretWithSpaces"></kbd>
                </figcaption>
              </figure>
              <b-form-group
                :state="otpAttemptState"
                label="After scanning the barcode image, the app will display a six-digit code that you can enter below."
                required
              >
                <b-form-input
                  v-model="otpAttempt"
                  :state="otpAttemptState"
                  type="number"
                  name="user[otp_attempt]"
                  autocomplete="off"
                  required
                />
              </b-form-group>
            </template>
            <template #nextStepButton>
              <b-button type="submit" variant="primary"> Enable 2FA </b-button>
            </template>
          </accordion-step>
        </accordion-wrapper>
      </xemx-form>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.user-security-2fa-form {
  &__content {
    max-width: 550px;
  }
}
</style>
