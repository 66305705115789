<script>
import ExpirationDateForm from '@/components/ExpirationDateForm'
import FormButton from '@/components/FormButton'

export default {
  name: 'UserAccount',
  components: {
    ExpirationDateForm,
    FormButton
  },
  filters: {
    formatDatetime(datetime, dateStyle = 'long', timeStyle = 'short') {
      const date = new Date(datetime)
      const options = { dateStyle, timeStyle }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    here() {
      return `${this.root}#/account`
    },
    blockUserData() {
      return { to_remove: ['user'] }
    },
    activateUserData() {
      return { to_add: ['user'] }
    },
    canSuspendUser() {
      return this.hasPermission('change_ldap_groups') && !this.user.roles.includes('admin')
    }
  },
  methods: {
    generateUrl(path) {
      return this.root + path
    },
    hasPermission(name) {
      return this.userPermissions[name]
    },
    async deleteUser() {
      await this.api.deleteUser(this.user.id)
      window.location.replace('/users')
    }
  }
}
</script>

<template>
  <div class="user-account">
    <h3>Account</h3>
    <dl class="mb-5">
      <template v-if="user.has_roles">
        <dt>Roles</dt>
        <dd>
          <b-badge v-for="role in user.roles" :key="role" variant="dark">
            {{ role }}
          </b-badge>
          <p class="text-muted small">Roles define the user's permissions.</p>
        </dd>
      </template>

      <dt>Last log-in</dt>
      <dd>
        <span v-if="user.last_sign_in_at">
          {{ user.last_sign_in_at | formatDatetime }}
        </span>
        <em v-else>This user never logged in.</em>
        <p class="text-muted small">
          Keeping track of unused accounts help us to preserve the security of ICIJ infrastructures.
          <a href="#/security/login-history">See login history</a>.
        </p>
      </dd>

      <dt>Lock status</dt>
      <dd>
        <div v-if="user.locked">
          <span> This user is locked. </span>
          <p class="text-muted small">Users get locked after 5 failed attempts to log in.</p>
          <form-button
            v-if="hasPermission('unlock')"
            :url="generateUrl('/unlock')"
            :redirect="here"
            variant="primary"
            size="sm"
          >
            <i class="fas fa-unlock mr-1"></i>
            Unlock
          </form-button>
        </div>
        <em v-else>This user is not currently locked.</em>
      </dd>

      <template v-if="canSuspendUser">
        <dt>Status</dt>
        <dd>
          <p class="mb-0">
            <template v-if="user.in_ldap_user_groups">
              <fa icon="check" fixed-width class="text-success mr-1" />
              This is ICIJ account activated.
            </template>
            <template v-else>
              <fa icon="ban" fixed-width class="text-danger mr-1" />
              This is ICIJ account is deactived.
            </template>
          </p>
          <p class="text-muted small mb-2">You can temporary block or restore this account.</p>
          <form-button
            v-if="user.in_ldap_user_groups"
            :url="generateUrl('/deactivate')"
            :redirect="here"
            variant="primary"
            size="sm"
          >
            <i class="fas fa-user-times mr-1"></i>
            Suspend access
          </form-button>
          <form-button
            v-if="!user.in_ldap_user_groups"
            :url="generateUrl('/activate')"
            :redirect="here"
            variant="primary"
            size="sm"
          >
            <i class="fas fa-user-plus mr-1"></i>
            Restore access
          </form-button>
        </dd>
      </template>
    </dl>

    <section v-if="canSuspendUser" class="mb-5">
      <h3>Expiration date</h3>
      <p v-if="!user.expires_at" class="mb-2">No expiration date set yet.</p>
      <expiration-date-form :user="user" />
      <div class="text-muted small">User will be scheduled for deactivation on this date.</div>
    </section>

    <section v-if="hasPermission('destroy')" class="mb-5">
      <h3>Delete account</h3>
      <p>
        This will delete the ICIJ account forever, including all records of activities. If you suspect any wrongdoings
        by this user, we suggest to suspend the account in order to preserve all information associated with this user.
        This operation cannot be undone, please proceed carefully.
      </p>
      <b-button v-b-modal.modal-prevent-closing variant="outline-danger">
        <i class="fas fa-trash-alt"></i>
        Delete
      </b-button>
      <b-modal id="modal-prevent-closing" ref="modal" title="Are you sure?" @ok="deleteUser">
        This operation cannot be undone.
      </b-modal>
    </section>
  </div>
</template>
