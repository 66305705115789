<script>
import UserSignIns from '@/components/UserSignIns'

export default {
  name: 'UserSecurityLoginHistory',
  components: {
    UserSignIns
  },
  filters: {
    formatDatetime(datetime, dateStyle = 'long', timeStyle = 'short') {
      const date = new Date(datetime)
      const options = { dateStyle, timeStyle }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      logInHistory: []
    }
  },
  async created() {
    this.busy = true
    this.logInHistory = await this.fetchLogInHistory()
    this.busy = false
  },
  methods: {
    async fetchLogInHistory() {
      const { log_in_history: logInHistory } = await this.api.getLogInHistory(this.user.id)
      return logInHistory ?? []
    },
    hasPermission(name) {
      return this.userPermissions[name]
    }
  }
}
</script>

<template>
  <div class="user-security-login-history">
    <h3>Sessions</h3>
    <p class="text-muted">Keep track of all sessions recorded for this account.</p>
    <user-sign-ins :user="user" />

    <h3>Login History</h3>
    <p class="text-muted">Keep track of all login to ICIJ platforms using this account.</p>
    <b-overlay :show="busy">
      <div v-if="logInHistory.length" class="table-responsive">
        <table class="table table-hover table-striped">
          <thead>
            <tr>
              <th>Log-in date</th>
              <th>Application</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(logIn, index) in logInHistory" :key="index">
              <td>{{ logIn.created_at | formatDatetime }}</td>
              <td>{{ logIn.application.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-else-if="!busy" class="card card-body text-center text-muted small">
        This user has not recently used his/her ICIJ account to access to other ICIJ platforms.
      </p>
    </b-overlay>
  </div>
</template>
