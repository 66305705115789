<template>
  <div class="onboarding-step-welcome pb-4">
    <div class="d-flex flex-column">
      <onboarding-title>Hello {{ username }}, welcome!<br />We'll follow these steps:</onboarding-title>
      <div class="d-flex mx-auto col-10 justify-content-around">
        <ol class="m-4 font-weight-bold">
          <li class="onboarding-step-welcome__create-password px-3 pb-3">
            <span class="ml-4">Create your password</span>
          </li>
          <li class="onboarding-step-welcome__upload-pgp p-3">
            <span class="ml-4">Create and/or upload your PGP key</span>
          </li>
          <li class="onboarding-step-welcome__check-email p-3">
            <span class="ml-4">Check your emails to confirm your ICIJ Account</span>
          </li>
          <li class="onboarding-step-welcome__2fa p-3">
            <span class="ml-4"
              >Install 2-factor authentication (<abbr title="two-factor authentication">2FA</abbr>) on your phone</span
            >
          </li>
          <li class="onboarding-step-welcome__enjoy px-3 pt-3">
            <span class="ml-4">Log in and enjoy ICIJ services</span>
          </li>
        </ol>
        <div class="col-4 d-none d-lg-block my-auto">
          <img src="@/assets/onboarding/developer.svg" alt="Developer sitting with a computer" class="img-fluid" />
        </div>
      </div>

      <div class="col-9">
        <onboarding-button class="onboarding-step-welcome__lets-start" href="/onboarding/create-password"
          >Let's start!</onboarding-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import OnboardingTitle from '@/components/onboarding/OnboardingTitle'
import OnboardingButton from '@/components/onboarding/OnboardingButton'

export default {
  name: 'OnboardingStepWelcome',
  components: {
    OnboardingTitle,
    OnboardingButton
  },
  props: {
    username: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utils/variables';

.onboarding-step-welcome {
  --counter-size: 1.5em;
  --counter-position: -28px;

  ol {
    list-style: none;
    counter-reset: welcome-steps;

    li {
      counter-increment: welcome-steps;
      border-left: 1px solid $info;
      display: flex;
      flex-wrap: wrap;
      &::before {
        content: counter(welcome-steps);
        color: $light;
        background-color: $info;
        font-weight: bold;
        border-radius: 50%;
        height: var(--counter-size);
        width: var(--counter-size);
        position: absolute;
        display: inline-block;
        text-align: center;
        margin-left: var(--counter-position);
      }
    }
  }
}
</style>
