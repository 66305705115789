<script>
import XemxForm from '@/components/XemxForm'

export default {
  name: 'UserSecurityPassword',
  components: { XemxForm },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    updatePasswordUrl() {
      return `${this.root}/update_password`
    }
  }
}
</script>

<template>
  <div class="user-security-password">
    <h3>Password</h3>
    <div class="row">
      <xemx-form class="col-12 col-lg-6 mb-2" method="POST" :action="updatePasswordUrl">
        <p class="text-muted">
          To help us keep ICIJ platforms safe, we hurge you to use a strong password that you're not using elsewhere.
        </p>

        <b-form-group v-if="user.is_me" label="Old password" required>
          <b-form-input type="password" name="current_password" required />
        </b-form-group>

        <b-form-group
          description="12 characters minimum, including 1 uppercase letter, 1 number."
          label="New password"
          minlength="12"
          required
        >
          <b-form-input type="password" name="password" required />
        </b-form-group>

        <b-form-group label="Re-type new password" minlength="12" required>
          <b-form-input type="password" name="password_confirmation" required />
        </b-form-group>

        <b-button type="submit" variant="primary"> Update password </b-button>
      </xemx-form>
    </div>
  </div>
</template>
