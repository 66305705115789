<script>
export default {
  name: 'SshKeys',
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      sshKeys: [],
      isActive: null
    }
  },
  computed: {
    newSshKeyUrl() {
      return `/users/${this.user.id}/ssh_keys/new`
    }
  },
  async created() {
    this.sshKeys = await this.fetchSshKeys()
  },
  methods: {
    async fetchSshKeys() {
      const { ssh_public_keys: sshKeys } = await this.api.getSSHKeys(this.user.id)
      return sshKeys ?? []
    },
    async deleteSshKey(id) {
      await this.api.deleteSSHKey(this.user.id, id)
      this.sshKeys = await this.fetchSshKeys()
    },
    sshKeyPairPad(key, classList = 'pair') {
      return key.replace(/(\w{2})/g, `<span class="${classList}">$1</span>`)
    },
    toggleItem(index) {
      this.isActive = this.isActive === index ? null : index
    }
  }
}
</script>

<template>
  <div class="ssh-keys">
    <div class="d-flex align-items-start mb-3">
      <p class="flex-grow-1 pr-2 text-muted">
        This is a list of SSH keys associated with your account. Remove any keys that you do not recognize.
      </p>
      <div>
        <b-button size="sm" variant="primary" :href="newSshKeyUrl"> New SSH key </b-button>
      </div>
    </div>

    <b-list-group>
      <b-list-group-item
        v-for="(key, index) in sshKeys"
        :key="index"
        class="ssh-keys__item"
        :class="{ 'bg-light': isActive === index }"
      >
        <div class="d-flex flex-row align-items-center" @click="toggleItem(index)">
          <div class="p-2 ssh-keys__item__icon">
            <fa icon="key" />
          </div>
          <div class="p-2 flex-grow-1 ssh-keys__item__key text-monospace">
            <span v-html="sshKeyPairPad(key.fingerprint, 'ssh-keys__item__key__pair')"></span>
          </div>
          <div class="ssh-keys__item__delete text-nowrap">
            <a href="#" class="btn btn-outline-danger btn-sm" @click.prevent.stop="deleteSshKey(key.id)">
              <fa icon="trash" />
              Delete
            </a>
          </div>
        </div>
        <div v-if="isActive === index" class="ssh-keys__item__value py-2">
          <textarea class="small text-monospace w-100 p-2 h-100" readonly :value="key.key"></textarea>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<style lang="scss" scoped>
.ssh-keys {
  &__item {
    &__value textarea {
      word-break: break-all;
    }
  }
}
</style>
