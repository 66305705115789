import Murmur from '@icij/murmur'
import { faUsb } from '@fortawesome/free-brands-svg-icons'

import UserProfileEdit from '../components/UserProfileEdit'
import UserAccount from '../components/UserAccount'
import UserEmails from '../components/UserEmails'
import UserAccessInvitations from '../components/UserAccessInvitations'
import UserAccessGroups from '../components/UserAccessGroups'
import UserAccessBlockedApplications from '../components/UserAccessBlockedApplications'
import UserAccessPermissions from '../components/UserAccessPermissions'
import UserSecurity2FA from '../components/UserSecurity2FA'
import UserSecurityActivities from '../components/UserSecurityActivities'
import UserSecurityClientCertificates from '../components/UserSecurityClientCertificates'
import UserSecurityLoginHistory from '../components/UserSecurityLoginHistory'
import UserSecurityPassword from '../components/UserSecurityPassword'
import UserSecurityPhysicalKeys from '../components/UserSecurityPhysicalKeys'
import UserSystemsLDAP from '../components/UserSystemsLDAP'
import UserSystemsSSHKeys from '../components/UserSystemsSSHKeys'
import UserSystemsUNIX from '../components/UserSystemsUNIX'
import UserSystemsVPN from '../components/UserSystemsVPN'

Murmur.config
  .scope('users')
  .scope('show')
  .set('pages', [
    {
      href: '/profile',
      name: 'Profile',
      section: '',
      component: UserProfileEdit,
      icon: 'user',
      permissions: ['update', 'update_profile']
    },
    {
      href: '/account',
      name: 'Account',
      section: '',
      component: UserAccount,
      icon: 'gear',
      permissions: ['update', 'remove_from_xemx_group']
    },
    {
      href: '/email',
      name: 'Email',
      section: '',
      component: UserEmails,
      icon: 'envelope',
      permissions: ['test_email', 'update_email', 'skip_user_confirmation_email'],
      warning: (user) => (user.pgp_key_expired ? 'PGP key expired' : null)
    },
    {
      href: '/access/invitations',
      name: 'Invitations',
      section: 'Access',
      component: UserAccessInvitations,
      icon: 'paper-plane',
      permissions: ['send_user_invitation_email']
    },
    {
      href: '/access/groups',
      name: 'Groups',
      section: 'Access',
      component: UserAccessGroups,
      icon: 'users',
      permissions: ['add_to_xemx_group', 'remove_from_xemx_group']
    },
    {
      href: '/access/blocked-applications',
      name: 'Blocked applications',
      component: UserAccessBlockedApplications,
      section: 'Access',
      icon: 'ban',
      permissions: ['update_blocked_applications']
    },
    {
      href: '/access/permissions',
      name: 'Permissions',
      component: UserAccessPermissions,
      section: 'Access',
      icon: 'lock',
      permissions: ['add_to_xemx_group', 'remove_from_xemx_group']
    },
    {
      href: '/security/password',
      name: 'Password',
      component: UserSecurityPassword,
      section: 'Security',
      icon: 'shield',
      permissions: ['update_password']
    },
    {
      href: '/security/2fa',
      name: '2FA',
      component: UserSecurity2FA,
      section: 'Security',
      icon: 'mobile-screen-button',
      permissions: ['activate_two_factor', 'deactivate_two_factor', 'manage_physical_keys'],
      warning: (user) => (!user.two_factor_enabled ? '2FA is not configured' : null)
    },
    {
      href: '/security/physical-keys',
      name: 'Physical keys',
      component: UserSecurityPhysicalKeys,
      section: 'Security',
      icon: faUsb,
      permissions: ['manage_physical_keys']
    },
    {
      href: '/security/client-certificate',
      name: 'Client certificate',
      component: UserSecurityClientCertificates,
      section: 'Security',
      icon: 'certificate',
      permissions: ['create_certificate', 'send_certificate_by_email', 'revoke_certificate'],
      warning: (user) => (user.user_certificate_expired ? 'Certificate is expired' : null)
    },
    {
      href: '/security/login-history',
      name: 'Login history',
      component: UserSecurityLoginHistory,
      section: 'Security',
      icon: 'id-card',
      permissions: ['log_in_history']
    },
    {
      href: '/security/activities',
      name: 'Activities',
      component: UserSecurityActivities,
      section: 'Security',
      icon: 'clock-rotate-left',
      permissions: ['activity_log']
    },
    {
      href: '/systems/ssh-keys',
      name: 'SSH keys',
      section: 'Systems',
      component: UserSystemsSSHKeys,
      icon: 'key',
      permissions: ['create_ssh_key', 'destroy_ssh_key', 'list_ssh_keys', 'new_ssh_key']
    },
    {
      href: '/systems/ldap',
      name: 'LDAP',
      section: 'Systems',
      component: UserSystemsLDAP,
      icon: 'address-book',
      permissions: ['change_ldap_groups', 'sync']
    },
    {
      href: '/systems/vpn',
      name: 'VPN',
      section: 'Systems',
      component: UserSystemsVPN,
      icon: 'globe',
      permissions: ['update_vpn_access']
    },
    {
      href: '/systems/unix',
      name: 'UNIX',
      section: 'Systems',
      component: UserSystemsUNIX,
      icon: 'terminal',
      permissions: ['add_to_posix_group', 'remove_from_posix_group', 'update_posix_status']
    }
  ])
