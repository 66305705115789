<template>
  <accordion-wrapper v-model="step" :steps="steps" class="mb-3">
    <accordion-step
      :step="pgpSteps.MAILVELOPE"
      class="accordion-step__mailvelope"
      title="Install Mailvelope and create your key"
    >
      <template #content>
        <p>
          Click here:
          <a href="https://mailvelope.com/en/help#intro">https://mailvelope.com/en/help#intro</a>
          <br />
          Follow these 3 steps:
        </p>
        <ol>
          <li>"Install Mailvelope on your browser" (step 1)</li>
          <li>"Open Mailvelope" (step 2)</li>
          <li>
            "Add your keypair" (step 3) but
            <span class="font-weight-bold">at this step, please carefully:</span>
            <ul>
              <li>Click "Don't have a key pair or need a new one? Mailvelope will help you generate a new one"</li>
              <li>
                In "Keyring" > "Generate", click "Advanced", keep "RSA" and "4096 Bit"
                <span class="font-weight-bold text-primary">but do set an expiration date (in 1, 2 or 10 years)</span>
              </li>
            </ul>
          </li>
        </ol>
      </template>
    </accordion-step>
    <accordion-step
      :step="pgpSteps.EXPORT_PUBLIC_KEY"
      title="Export your public key"
      class="accordion-step__public-key"
    >
      <template #content>
        <p class="pb-0">
          Follow the explanations here:
          <a href="https://mailvelope.com/en/faq#export_pub_key"
            >"How do I export my public key to give it to someone else?"</a
          >
        </p>
        <p>If you now have your public PGP key as a .asc file downloaded on your computer, you can continue!</p>
      </template>
    </accordion-step>
    <accordion-step :step="pgpSteps.UPLOAD_PUBLIC_KEY" class="accordion-step__upload-public-key">
      <template #title>
        Upload your
        <span class="font-weight-bold text-primary">public</span> key
      </template>
      <template #content>
        <onboarding-pgp-key-input-with-criteria
          :email="email"
          :user-id="userId"
          @pgp-input-changed="onPgpInputChanged"
        />
      </template>
      <template #nextStepButton>
        <onboarding-pgp-upload-button class="d-inline-flex" :pgp-key="pgpKey" :disabled="!enableUpload" />
      </template>
    </accordion-step>
  </accordion-wrapper>
</template>

<script>
import { AccordionStep, AccordionWrapper } from '@icij/murmur'

import OnboardingPgpKeyInputWithCriteria from '@/components/onboarding/OnboardingPgpKeyInputWithCriteria'
import OnboardingPgpUploadButton from '@/components/onboarding/OnboardingPgpUploadButton'

const PGP_STEPS = Object.freeze({
  MAILVELOPE: Symbol('MAILVELOPE'),
  EXPORT_PUBLIC_KEY: Symbol('EXPORT_PUBLIC_KEY'),
  UPLOAD_PUBLIC_KEY: Symbol('UPLOAD_PGP')
})

export default {
  name: 'OnboardingCreatePgpKeyAccordion',
  components: {
    AccordionWrapper,
    AccordionStep,
    OnboardingPgpKeyInputWithCriteria,
    OnboardingPgpUploadButton
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      enableUpload: false,
      pgpKey: '',
      step: PGP_STEPS.MAILVELOPE,
      pgpSteps: PGP_STEPS
    }
  },
  computed: {
    steps() {
      return Object.values(this.pgpSteps)
    }
  },
  methods: {
    onPgpInputChanged({ isValid, pgpKey }) {
      this.enableUpload = isValid
      this.pgpKey = pgpKey
    }
  }
}
</script>
