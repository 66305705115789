<template>
  <b-form @submit.prevent="applyGroupChanges">
    <b-card>
      <b-form-group :label="label">
        <b-form-checkbox-group
          switches
          :checked="defaultCheckedGroups"
          :options="computedOptions"
          stacked
          @input="storeCheckedGroups"
        />
      </b-form-group>
      <slot name="bellow" />
      <template #footer>
        <b-button size="sm" variant="primary" type="submit"> Update </b-button>
      </template>
    </b-card>
  </b-form>
</template>

<script>
export default {
  name: 'FormGroups',
  props: {
    preCheckedGroups: {
      type: Array,
      required: true
    },
    optionsForSelect: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checkedGroups: []
    }
  },
  computed: {
    defaultCheckedGroups() {
      return this.preCheckedGroups
    },
    computedOptions() {
      return this.optionsForSelect.map((option) => {
        return {
          // Option text must fallback to "value"
          text: option.value,
          ...option
        }
      })
    }
  },
  mounted() {
    this.checkedGroups = this.preCheckedGroups
  },
  methods: {
    storeCheckedGroups(value) {
      this.checkedGroups = value
    },
    applyGroupChanges(event) {
      this.$emit('updateGroups', this.checkedGroups)
    }
  }
}
</script>
