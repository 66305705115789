<template>
  <div v-if="!dismiss" :class="flashClass" role="alert" class="alert border-0 rounded-0 mb-0">
    <div class="container position-relative">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="dismiss = true">
        <span aria-hidden="true">&times;</span>
      </button>
      <ul v-for="(singleMessage, index) in messages" :key="index" class="list-unstyled m-0">
        <li class="list-unstyled-item">
          {{ singleMessage }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { castArray } from 'lodash'

export default {
  name: 'FlashMessage',
  props: {
    message: {
      type: [String, Array],
      required: true
    },
    variant: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dismiss: false
    }
  },
  computed: {
    flashClass() {
      return `alert-${this.variant}`
    },
    messages() {
      return castArray(this.message)
    }
  },
  methods: {
    isFieldName(field) {
      return !isNaN(field)
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  & .close {
    top: 0;
  }
}
</style>
