<script>
import FormButton from '@/components/FormButton'
import ManageUserLdapGroups from '@/components/ManageUserLdapGroups'

export default {
  name: 'UserSystemsLDAP',
  components: {
    FormButton,
    ManageUserLdapGroups
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    }
  },
  methods: {
    generateUrl(path) {
      return `${this.root}${path}`
    }
  }
}
</script>

<template>
  <div class="user-systems-ldap">
    <h3>LDAP</h3>
    <p class="text-muted">
      ICIJ accounts are both available through this website and an OpenLDAP server. This allows ICIJ to provide
      single-sign-on to all its platforms using different technologies (OAuth2, OpenID and LDAP).
    </p>
    <div class="row">
      <div v-if="userPermissions.change_ldap_groups" class="col-12 col-lg-6">
        <manage-user-ldap-groups :user="user" class="mb-3" />
      </div>
      <div v-if="userPermissions.sync" class="col-12 col-lg-6">
        <b-card class="mb-3">
          <dl class="mb-0">
            <dt>Sync data with LDAP</dt>
            <dd>
              Use this button to manually synchronize the account data with the LDAP directory (this might change the
              user role).
            </dd>
          </dl>
          <template #footer>
            <form-button :url="generateUrl('/sync')" :redirect="root" variant="primary" size="sm"> Sync </form-button>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>
