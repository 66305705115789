<template>
  <div class="bulk-import-form mx-auto">
    <xemx-form class="bulk-import" method="POST" action="/users/bulk_import" enctype="multipart/form-data">
      <div class="card card-body shadow-sm mb-4">
        <div class="form-group">
          <div class="d-flex align-items-center">
            <label for="csv-file-input">Select CSV of users</label>
            <p class="small text-right ml-auto mb-2 p-0">
              <a
                href="https://docs.google.com/spreadsheets/d/1aPqx83lDpzPwh46KYoOlucPYas7V-XgkCKfvDYqDThg/edit"
                target="_blank"
              >
                <fa icon="fa-file-csv" class="mr-1" />
                CSV template
              </a>
            </p>
          </div>
          <b-form-file
            id="csv-file-input"
            name="file"
            placeholder="Choose a file or drop it here. Please only choose CSV files."
            drop-placeholder="Drop CSV file here..."
            accept="text/csv"
          />
        </div>

        <div class="form-group mt-3">
          <div class="custom-control custom-checkbox">
            <input id="show-projects-input" v-model="showProjects" type="checkbox" class="custom-control-input" />
            <label class="custom-control-label" for="show-projects-input"> Bulk add users to project? </label>
            <small class="form-text text-muted">
              This will add every valid user included in the CSV to a project, if that user is not already a member.
            </small>
          </div>
          <div v-if="showProjects" class="mt-3">
            <b-form-select v-model="selectedProject" name="selected_project" :options="projectGroups" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary">Import</button>
      </div>
    </xemx-form>
  </div>
</template>

<script>
import XemxForm from '@/components/XemxForm'

export default {
  name: 'BulkImportForm',
  components: { XemxForm },
  inject: ['api'],
  data() {
    return {
      showProjects: false,
      projectGroups: null,
      selectedProject: null,
      file: null
    }
  },
  watch: {
    showProjects(show) {
      if (!show) {
        this.selectedProject = null
      }
    }
  },
  async mounted() {
    await this.setProjects()
  },
  methods: {
    async setProjects() {
      const emptyOption = { value: null, text: 'Select a group' }
      const groups = await this.api.getGroupList()
      const options = groups.map(({ id: value, label, name }) => {
        const text = label ?? name
        return { value, text }
      })

      this.projectGroups = [emptyOption, ...options]
    }
  }
}
</script>

<style scoped lang="scss">
.bulk-import-form {
  max-width: 660px;
}
</style>
