<template>
  <b-overlay :show="busy" :class="overlayClassList" opacity="0.6" spinner-small :spinner-variant="variant">
    <component :is="tag" :size="size" :variant="variant" :disabled="disabled || busy" @click="postAndRedirectToRoot">
      <slot />
    </component>
  </b-overlay>
</template>

<script>
/**
 * A button inside a form to send a post request to a given URL.
 */
export default {
  name: 'FormButton',
  inject: ['api'],
  props: {
    /**
     * The URL to send the data to
     */
    url: {
      type: String,
      required: true
    },
    /**
     * The URL to redirect to after the backend process the form data correctly
     */
    redirect: {
      type: String,
      default: null
    },
    /**
     * Size of the button (if applicable)
     */
    size: {
      type: String,
      default: null
    },
    /**
     * Variant of the button (if applicable)
     */
    variant: {
      type: String,
      required: true
    },
    /**
     * Tag/Component to use to build the button.
     */
    tag: {
      type: [String, Object],
      default: 'b-button'
    },
    /**
     * HTTP method to use to send the data to the backend
     */
    method: {
      type: String,
      default: 'post',
      validator: (v) => ['delete', 'post', 'get', 'patch', 'put', 'head'].includes(v)
    },
    /**
     * Data object to send to the backend
     */
    data: {
      type: Object,
      default: () => ({})
    },
    /**
     * Disable the button when true
     */
    disabled: {
      type: Boolean
    },
    /**
     * The value to set the button's 'type' attribute to. Can be one of 'button', 'submit', or 'reset'
     */
    type: {
      type: String,
      default: 'button',
      validator: (v) => ['button', 'submit', 'reset'].includes(v)
    }
  },
  data() {
    return {
      busy: false
    }
  },
  computed: {
    overlayClassList() {
      return {
        'd-inline-block': ['button', 'b-button'].includes(this.tag)
      }
    }
  },
  methods: {
    async postAndRedirectToRoot() {
      this.busy = true
      try {
        const response = await this.api.sendAction(this.url, {
          method: this.method,
          data: this.data
        })
        /**
         * Emitted when the backend processed the data successfully
         * @event submit
         */
        this.$emit('submit', response)
        // Callback redirection is optional
        if (this.redirect) {
          this.redirectTo(this.redirect)
        }
      } catch (error) {
        /**
         * Emitted when the backend returned an error
         * @event error
         */
        this.$emit('error', { error })
      }
      this.busy = false
    },
    redirectTo(path) {
      if (window.location.href.endsWith(path)) {
        window.location.reload()
      } else {
        window.location.replace(path)
      }
    }
  }
}
</script>
