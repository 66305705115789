<script>
import ManageUserVpnAccess from '@/components/ManageUserVpnAccess'

export default {
  name: 'UserSystemsVPN',
  components: { ManageUserVpnAccess },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div class="user-systems-vpn">
    <h3>VPN</h3>
    <p class="text-muted">ICIJ uses different internal VPCs to protect its infrastructures.</p>
    <div class="row">
      <div class="col-12 col-lg-6">
        <manage-user-vpn-access :user="user" />
      </div>
    </div>
  </div>
</template>
