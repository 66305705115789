<script>
import ManagePhysicalKeys from '@/components/ManagePhysicalKeys'

export default {
  name: 'UserSecurityPhysicalKeys',
  components: {
    ManagePhysicalKeys
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div class="user-security-physical-keys">
    <h3>Physical Keys</h3>
    <div class="row">
      <div class="mb-5 col-12 col-lg-7">
        <p class="text-muted">
          Physical keys that can be used as your second factor of authentication instead of a verification code from an
          authenticator app. If you want to buy such device, we recommend YubiKeys by
          <a href="https://www.yubico.com/store/">Yubico</a>.
        </p>
        <manage-physical-keys :user-id="user.id" />
      </div>
    </div>
  </div>
</template>
