<template>
  <div v-cloak v-if="warningActivated" class="two-f-a-warning">
    <div class="two-f-a-warning__panel mb-4">
      <div class="two-f-a-warning__panel__heading">
        <h3>Help us keep ICIJ safe</h3>
      </div>
      <div class="two-f-a-warning__panel__body">
        <p class="lead">
          Please enable two-factor authentication. This is mandatory and crucial for the security of the platforms. You
          will lose access if this is not enabled.
        </p>
        <div v-if="!userMandatoryOtpActivated" class="">
          <p class="small">
            Your account will be deactivated in
            {{ deactivationDate }}
            if you don't enable 2FA.
          </p>
        </div>
      </div>
      <div class="two-f-a-warning__panel__footer">
        <a :href="manageTwoFactor" class="btn btn-light mr-1">
          <font-awesome-icon icon="lock" />
          <strong> Enable 2FA </strong>
        </a>
        <button class="btn btn-outline-light" @click="remindMeLater">Remind me later</button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'TwoFAWarning',
  props: {
    userId: {
      type: String,
      required: true
    },
    cookieName: {
      type: String,
      default: '2fa-remind-me'
    },
    deactivationDate: {
      type: String,
      required: true
    },
    userMandatoryOtpActivated: {
      type: Boolean
    }
  },
  data() {
    return {
      warningActivated: !Cookies.get(this.cookieName)
    }
  },
  computed: {
    manageTwoFactor() {
      return `/users/${this.userId}/two_factor`
    }
  },
  methods: {
    remindMeLater() {
      // Expire in 2 days
      Cookies.set(this.cookieName, true, { expires: 2 })
      // Change current states
      this.warningActivated = false
    }
  }
}
</script>
