<script>
import { cloneDeep } from 'lodash'

import XemxForm from '@/components/XemxForm'
import UserAccessGroupsEntry from '@/components/UserAccessGroupsEntry'

export default {
  name: 'UserAccessGroups',
  components: {
    XemxForm,
    UserAccessGroupsEntry
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      groups: [],
      userGroups: cloneDeep(this.user.user_groups)
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    updateGroupsUrl() {
      return `${this.root}/update_groups`
    }
  },
  async created() {
    this.busy = true
    this.groups = await this.api.getGroups()
    this.busy = false
  },
  methods: {
    hasSameGroupId({ group_id: id }, groupId) {
      return id === groupId
    },
    findUserGroup(groupId) {
      return this.userGroups.find((userGroup) => this.hasSameGroupId(userGroup, groupId))
    },
    findUserGroupIndex(groupId) {
      return this.userGroups.findIndex((userGroup) => this.hasSameGroupId(userGroup, groupId))
    },
    updateGroupMembership({ groupId, partner, _destroy }) {
      const userGroupIndex = this.findUserGroupIndex(groupId)

      if (userGroupIndex === -1) {
        const newGroup = {
          user_id: this.user.id,
          group_id: groupId,
          partner,
          _destroy
        }
        this.userGroups = [...this.userGroups, newGroup]
      } else {
        const updatedUserGroups = this.userGroups.map((userGroup, index) => {
          if (index === userGroupIndex) {
            const modifiedUserGroup = {
              ...userGroup,
              partner,
              _destroy
            }
            return modifiedUserGroup
          }
          return userGroup
        })
        this.userGroups = [...updatedUserGroups]
      }
    },
    async handleSubmit() {
      try {
        await this.api.updateGroups(this.updateGroupsUrl, this.userGroups)
      } finally {
        window.location.reload()
      }
    }
  }
}
</script>

<template>
  <div class="user-access-groups">
    <h3>Groups</h3>
    <div class="row">
      <xemx-form class="col-12 col-lg-8 mb-2" @submit.prevent="handleSubmit">
        <p class="text-muted">
          Adding a user to a group will grant them access to all the applications each group is associated to. To
          prevent a user from accessing specific applications, please change their
          <a href="#/access/blocked-applications">blocked applications list</a>.
        </p>
        <b-form-group>
          <b-overlay :show="busy" rounded class="user-access-groups__checkbox-group-overlay">
            <div class="card p-4 mb-2 user-access-groups__card">
              <div v-for="group in groups" :key="group.id">
                <user-access-groups-entry
                  :group-id="group.id"
                  :group-label="group.label_or_name"
                  :group-description="group.description"
                  :user-group="findUserGroup(group.id)"
                  @update:userGroup="updateGroupMembership"
                />
              </div>
            </div>
          </b-overlay>
          <b-button type="submit" variant="primary"> Update </b-button>
        </b-form-group>
      </xemx-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-access-groups {
  &__checkbox-group-overlay .card {
    min-height: 5rem;
  }

  &__card {
    max-height: 50vh;
    overflow: auto;
  }
}
</style>
