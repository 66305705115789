<template>
  <form-groups
    :user="user"
    :all-groups="allGroups"
    :pre-checked-groups="existingGroups"
    :options-for-select="optionsGroups"
    label="Edit LDAP groups"
    @updateGroups="updateLdapUserGroups"
  >
    <template #bellow>
      <p v-if="disableUser" class="text-muted mb-0">
        You must remove the account from admin and/or support groups before removing it from the user group.
      </p>
    </template>
  </form-groups>
</template>

<script>
import FormGroups from '@/components/FormGroups'

export default {
  name: 'ManageUserLdapGroups',
  components: {
    FormGroups
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      allGroups: ['admin', 'support', 'user']
    }
  },
  computed: {
    existingGroups() {
      return this.user.ldap_user_groups
    },
    disableUser() {
      const userGroups = this.existingGroups
      return userGroups.includes('user') && (userGroups.includes('admin') || userGroups.includes('support'))
    },
    optionsGroups() {
      return this.allGroups.map((group) => {
        return {
          text: group,
          value: group,
          disabled: group === 'user' && this.disableUser
        }
      })
    }
  },
  methods: {
    updateLdapUserGroups(value) {
      const current = this.user.ldap_user_groups
      const selected = value
      const difference = this.difference(current, selected)
      const toRemove = difference.filter((group) => current.includes(group))
      const toAdd = difference.filter((group) => !current.includes(group))
      this.makeLdapUserGroupRequest(toAdd, toRemove)
    },
    difference(first, second) {
      const a = new Set(first)
      const b = new Set(second)

      return [...first.filter((element) => !b.has(element)), ...second.filter((element) => !a.has(element))]
    },
    async makeLdapUserGroupRequest(toAdd, toRemove) {
      await this.api.updateUserEntryGroup(this.user.id, toAdd, toRemove)
      this.reload()
    },
    reload() {
      const redirectPath = `/users/${this.user.id}#/systems/ldap`
      if (window.location.href.endsWith(redirectPath)) {
        window.location.reload()
      } else {
        window.location.replace(redirectPath)
      }
    }
  }
}
</script>
