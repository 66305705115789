<template>
  <div>
    <template v-if="showForm">
      <b-form inline class="mt-4 mb-2" @submit.prevent="requestCredential">
        <b-form-group class="mr-2" label="Key nickname" label-class="sr-only">
          <b-form-input
            v-model="nickname"
            autofocus
            name="email"
            placeholder="Nickname for your key"
            type="text"
            required
          />
        </b-form-group>
        <b-overlay :show="busy" opacity="0.6" spinner-small class="d-inline-block">
          <b-button :disabled="!nickname || busy" variant="primary" type="submit"> Add </b-button>
        </b-overlay>
      </b-form>
      <p class="text-muted small">This is used to identify your keys. You cannot register the same key twice.</p>
    </template>
    <template v-else>
      <p>
        <b-button variant="primary" @click="showForm = true"> Add new key </b-button>
      </p>
    </template>

    <b-card v-if="credentials.length" no-body class="mb-4">
      <b-list-group flush>
        <b-list-group-item v-for="(credential, index) in credentials" :key="index" class="d-flex align-items-center">
          <div class="py-2 pr-2 text-center">
            <i class="fas fa-key mb-2"></i>
          </div>
          <div class="flex-grow-1">
            <strong>{{ credential.nickname }}</strong> — added on
            {{
              credential.created_at
                | formatDatetime({
                  dateStyle: 'medium'
                })
            }}
          </div>
          <div>
            <form-button
              method="delete"
              :url="deleteCredentialUrl(credential.id)"
              size="sm"
              variant="outline-danger"
              @submit="reload"
            >
              <i class="fas fa-trash"></i>
              Delete
            </form-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import get from 'lodash/get'

import { createCredentialFromCallback } from '@/utils/credential'
import FormButton from '@/components/FormButton'

export default {
  name: 'ManagePhysicalKeys',
  components: {
    FormButton
  },
  filters: {
    formatDatetime(datetime, { dateStyle = 'long', timeStyle = 'short' } = {}) {
      try {
        const date = new Date(datetime)
        const options = { dateStyle, timeStyle }
        return new Intl.DateTimeFormat('en-US', options).format(date)
      } catch (_) {
        return datetime
      }
    }
  },
  inject: ['api'],
  props: {
    userId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      busy: false,
      credentials: [],
      nickname: null,
      showForm: false
    }
  },
  computed: {
    root() {
      return `/users/${this.userId}`
    }
  },
  async mounted() {
    this.credentials = await this.fetchCredentials()
  },
  methods: {
    async fetchCredentials() {
      const response = await this.api.getWebAuthnCredentialList(this.userId)
      return get(response, 'credentials', [])
    },
    formatDate(date) {
      const object = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return object.toLocaleDateString('en-US', options)
    },
    createCredential(publicKey) {
      const nickname = this.nickname
      const callbackUrl = encodeURI(`${this.root}/webauthn_credentials/callback?credential_nickname=${nickname}`)
      const redirectTo = `/users/${this.userId}#/security/physical-keys`
      return createCredentialFromCallback({
        callbackUrl,
        publicKey,
        redirectTo
      })
    },
    deleteCredentialUrl(credentialId) {
      return `${this.root}/webauthn_credentials/${credentialId}`
    },
    async requestCredential() {
      try {
        this.busy = true
        const response = await this.api.postWebAuthnCredentials(this.userId)
        await this.createCredential(response)
      } catch (error) {
        console.log(error)
        this.$bvToast.toast(error.message, {
          title: 'Something went wrong',
          variant: 'danger'
        })
      } finally {
        this.busy = false
      }
    },
    reload() {
      const redirectPath = `${this.root}#/security/physical-keys`
      if (window.location.href.endsWith(redirectPath)) {
        window.location.reload()
      } else {
        window.location.replace(redirectPath)
      }
    }
  }
}
</script>
