<template>
  <div class="otp-form">
    <div class="form-signin__lead">
      <h2 class="h3 mb-4">Log in</h2>
    </div>
    <xemx-form action="/users/sign_in/challenge/otp" method="POST" @submit="submit">
      <div class="form-group mb-1">
        <label for="otp-form-attempt" class="font-weight-bold"> Enter two-factor authentication code </label>
        <input
          id="otp-form-attempt"
          aria-describedby="otpHelpBlock"
          autocomplete="off"
          autofocus
          class="form-control"
          maxlength="6"
          type="string"
          name="user[otp_attempt]"
        />
      </div>
      <p id="otpHelpBlock" class="small text-muted">
        Open the Authenticator app on your device to view the six-digit code
      </p>

      <div class="form-check form-group">
        <input type="hidden" name="fingerprint" :value="fingerprint" />
        <input id="user_remember" type="checkbox" class="form-check-input" name="remember" />
        <label for="user_remember" class="form-check-label">Remember this device</label>
      </div>

      <b-button block class="text-uppercase font-weight-bold" variant="primary" type="submit" :disabled="disabled">
        <font-awesome-icon v-if="$wait.is('otp attempt')" icon="rotate" spin fixed-width class="mr-1" />
        Verify
      </b-button>
    </xemx-form>
  </div>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import XemxForm from '@/components/XemxForm'

export default {
  name: 'TwoFactorOtpForm',
  components: { XemxForm },
  props: {
    webauthnEnabled: {
      type: Boolean
    }
  },
  data() {
    return {
      fingerprint: null
    }
  },
  computed: {
    disabled() {
      return this.$wait.is('otp attempt')
    }
  },
  async mounted() {
    this.fingerprint = await this.getVisitorId()
    await this.$nextTick()
    this.focusInput()
  },
  methods: {
    submit() {
      this.$wait.start('otp attempt')
    },
    focusInput() {
      this.$el.querySelector('#otp-form-attempt').focus()
    },
    async getVisitorId() {
      const fp = await FingerprintJS.load()
      const { visitorId } = await fp.get()
      return visitorId
    }
  }
}
</script>
