<script>
export default {
  name: 'CountrySelect',
  inject: ['api'],
  model: {
    prop: 'selected',
    event: 'input'
  },
  props: {
    name: {
      type: String,
      required: true
    },
    selected: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      busy: false,
      selectedCountry: this.selected,
      countries: []
    }
  },
  computed: {
    options() {
      return this.countries.map((country) => {
        return { text: country.iso_short_name, value: country.alpha2 }
      })
    }
  },
  watch: {
    selectedCountry(current) {
      this.$emit('input', current)
    }
  },
  async created() {
    try {
      this.busy = true
      this.countries = await this.api.getCountries()
    } finally {
      this.busy = false
    }
  }
}
</script>

<template>
  <b-overlay :show="busy" rounded spinner-small>
    <b-form-select v-model="selectedCountry" :name="name" :options="options">
      <slot v-bind="{ countries, options, selectedCountry }" />
    </b-form-select>
  </b-overlay>
</template>
