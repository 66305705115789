<script>
export default {
  name: 'CertificateForm',
  inject: ['api'],
  props: {
    userId: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      os: null,
      webbrowser: null,
      gpgclient: null,
      hasConfirmed: false,
      oss: {
        linux: 'GNU/Linux',
        mac: 'Mac OSX',
        windows: 'Windows'
      },
      webbrowsers: {
        firefox: 'Firefox',
        chrome: 'Chrome',
        other: 'Other'
      },
      gpgclients: {
        enigmail: 'Thunderbird with Enigmail',
        gpgtool: 'Apple Mail with GPG Tool',
        mailvelope: 'Mailvelope',
        other: 'Other'
      }
    }
  },
  methods: {
    valid() {
      return this.os !== null && this.emailclient !== null && this.gpgclient !== null
    },
    async confirm() {
      try {
        await this.api.sendCertificateOnce(this.userId)
      } finally {
        this.hasConfirmed = true
      }
    },
    confirmed() {
      return this.valid && this.hasConfirmed
    }
  }
}
</script>

<template>
  <div class="certificate-form mb-4">
    <form>
      <div>
        <p>
          As you may know, the URLs of ICIJ services have been accidentally published before. But our team has added
          several layers of security to our platforms and we encourage you to follow good practices (long passwords,
          2FA, etc). Today, to reinforce security, ICIJ is adding a third factor of authentication to its accounts:
          <strong>client SSL certificates</strong>.
        </p>
        <p>
          When you connect to a secure website, you might see an
          <abbr title="Hyper Text Transfer Protocol Secure">HTTPS</abbr> prefix in the address. This protocol is used to
          certify that the website you are consulting is authentic and that no one intercepted the communication to send
          you to malicious pages.
        </p>
        <p>
          <strong>Client SSL certificates</strong> work the other way around: they allow our servers to recognize your
          device and to ensure its authenticity. Using client certificates, we can, for instance, refuse any attempt to
          visit our platforms from a computer that doesn't have a certificate delivered by ICIJ.
        </p>
      </div>
      <h3 class="mt-5">Which tool do you use?</h3>
      <p class="text-muted">We need more information do give you customized instructions.</p>
      <div class="row">
        <div class="form-group col-md-4">
          <div class="card p-2" :class="{ 'text-success card-outline-success': os !== null }">
            <label>
              <span class="fa-stack fa-1x small">
                <i class="fa fa-circle fa-stack-2x"></i>
                <strong class="fa-stack-1x text-white">1</strong>
              </span>
              Operating system:
            </label>
            <select v-model="os" class="form-control">
              <option disabled value="">Please select one</option>
              <option v-for="(label, operatingSystem) in oss" :key="operatingSystem" :value="operatingSystem">
                {{ label }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div
            class="card p-2"
            :class="{
              'text-success card-outline-success': webbrowser !== null
            }"
          >
            <label>
              <span class="fa-stack fa-1x small">
                <i class="fa fa-circle fa-stack-2x"></i>
                <strong class="fa-stack-1x text-white">2</strong>
              </span>
              Web browser:
            </label>
            <select v-model="webbrowser" class="form-control">
              <option disabled value="">Please select one</option>
              <option
                v-for="(label, webbrowserOption) in webbrowsers"
                :key="webbrowserOption"
                :value="webbrowserOption"
              >
                {{ label }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="card p-2" :class="{ 'text-success card-outline-success': gpgclient !== null }">
            <label>
              <span class="fa-stack fa-1x small">
                <i class="fa fa-circle fa-stack-2x"></i>
                <strong class="fa-stack-1x text-white">3</strong>
              </span>
              GPG client
            </label>
            <select v-model="gpgclient" class="form-control">
              <option disabled value="">Please select one</option>
              <option v-for="(label, client) in gpgclients" :key="client" :value="client">
                {{ label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="text-right">
        <button class="btn btn-primary btn-lg" type="button" :disabled="!valid()" @click="confirm()">Continue</button>
      </div>
      <div v-if="confirmed()" v-cloak class="certificate-form__instructions mt-4">
        <h3>Instructions</h3>
        <ol>
          <li>
            You should have received an email called
            <samp class="subject">ICIJ SSL CERTIFICATE FOR {{ userName }}</samp>
            from <strong>pki@icij.org</strong>.
          </li>
          <li>Find and open this email with your email client.</li>
          <li>This email is encrypted with your PGP key. Decrypt it.</li>
          <!-- DOWNLOAD WITH ENIGMAIL -->
          <li v-if="gpgclient === 'enigmail'" class="certificate-form__instructions__step">
            In this email, you should see an attachment called
            <cite class="filename">ICIJ-certificate.p12</cite>.
            <img src="@/assets/images/certificate-enigmail.png" class="img-thumbnail my-2 screenshot" />
          </li>
          <!-- DOWNLOAD WITH MAILVELOPE -->
          <li v-else-if="gpgclient === 'mailvelope'" class="certificate-form__instructions__step">
            In this email, you should see an attachment called
            <cite class="filename">ICIJ-certificate.p12</cite>.
            <img src="@/assets/images/certificate-mailvelope.png" class="img-thumbnail my-2 screenshot" />
          </li>
          <!-- DOWNLOAD WITH APPLE MAIL -->
          <li v-else-if="gpgclient === 'gpgtool'" class="certificate-form__instructions__step">
            In this email, you should see an attachment called
            <cite class="filename">ICIJ-certificate.p12</cite>.
            <img src="@/assets/images/certificate-gpgtool.png" class="img-thumbnail my-2 screenshot" />
          </li>
          <!-- DOWNLOAD WITH OTHER EMAIL CLIENT -->
          <li v-else class="certificate-form__instructions__step">
            In this email, you should see an attachment called
            <cite class="filename">ICIJ-certificate.p12</cite>.
          </li>
          <!-- LINUX INSTALL WITH CHROME -->
          <template v-if="os === 'linux' && webbrowser === 'chrome'">
            <li>
              In the address bar, type
              <a href="chrome://settings" target="_blank">chrome://settings</a>. Alternatively, at the top right corner,
              click
              <img src="@/assets/images/menu-dots.svg" alt="More" />
              <img src="@/assets/images/and-then.png" alt=" and then" />
              settings.
            </li>
            <li>
              In the settings' search bar, look for
              <q>manage certificates</q> (it might be different if you use Chrome in another language). Click on
              <strong>Manage Certificates</strong> to open the settings page.
              <img src="@/assets/images/certificate-chrome-1.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>
              In this page, you should see a button "Import", click on it.
              <img src="@/assets/images/certificate-chrome-2.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>Select the certificate you downloaded on your computer.</li>
            <li>
              Chrome is going to ask you to
              <q>Enter your certificate password</q>.<br />
              <strong>You can find this password in the email used to get your certificate.</strong>
              <img src="@/assets/images/certificate-chrome-3.png" class="img-thumbnail my-2 screenshot" />
            </li>
          </template>
          <!-- MAC INSTALL WITHOUT FIREFOX -->
          <template v-else-if="os === 'mac' && webbrowser !== 'firefox'">
            <li>
              You must see the file on your computer:
              <img src="@/assets/images/certificate-mac-1.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>
              If you try to open the file, Mac OS should ask you for a password.
              <strong>You can find this password in the email used to get your certificate.</strong>
              You may have to enter this password manually if Mac OS prevents you from copy-pasting it.
              <img src="@/assets/images/certificate-mac-2.png" class="img-thumbnail my-2 screenshot" />
            </li>
          </template>
          <!-- WINDOWS INSTALL WITHOUT FIREFOX -->
          <template v-else-if="os === 'windows' && webbrowser !== 'firefox'">
            <li>
              You must see the file on your computer:
              <img src="@/assets/images/certificate-windows-1.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>
              If you try to open the file, Windows should ask you if you want to install it for the current user or the
              local machine.
              <br />Leave the default option (Current User) and click on "Next".
              <img src="@/assets/images/certificate-windows-2.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>
              Windows should ask you to confirm which file you want to import. Leave the default value and click on
              "Next".
              <img src="@/assets/images/certificate-windows-3.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>
              Windows should ask you a password.
              <strong>You can find this password in the email used to get your certificate.</strong><br />Copy the
              password in the dedicated field then click on "Next".
              <img src="@/assets/images/certificate-windows-4.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>
              Windows should ask you to choose a store where it will save your certificate.<br />
              Leave the default value ("Automatically select...") then click on "Next".
              <img src="@/assets/images/certificate-windows-5.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>
              You must now confirm the configuration. Simply click on "Finish".
              <img src="@/assets/images/certificate-windows-6.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>
              Finally, Windows should ask you to confirm you want to install the certificate. Click on "Yes".
              <img src="@/assets/images/certificate-windows-7.png" class="img-thumbnail my-2 screenshot" />
            </li>
          </template>
          <!-- INSTALL WITH FIREFOX -->
          <template v-else-if="webbrowser === 'firefox'">
            <li>
              In the address bar, type
              <a href="about:preferences" target="_blank">about:preferences</a>. Alternatively, from the top right
              corner
              <img src="@/assets/images/menu-stripes.png" alt="More" />
              <img src="@/assets/images/and-then.png" alt=" and then" />
              <strong>Preferences</strong>.
            </li>
            <li>
              In the settings' search bar, look for <q>certificates</q> (it might be different if you use Firefox in
              another language). Click on <strong>View Certificates</strong> to open a settings page.
              <img src="@/assets/images/certificate-firefox-1.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>
              In this page, you should see a button "Import", click on it.
              <img src="@/assets/images/certificate-firefox-2.png" class="img-thumbnail my-2 screenshot" />
            </li>
            <li>Select the certificate you downloaded on your computer.</li>
            <li>
              After you select the certificate, Firefox is going to ask you to
              <q>enter the password that was used to encrypt this certificate</q>.<br />
              <strong>You can find this password in the email used to get your certificate.</strong>
              <img src="@/assets/images/certificate-firefox-3.png" class="img-thumbnail my-2 screenshot" />
            </li>
          </template>
          <!-- DEFAULT INSTALL -->
          <template v-else>
            <li>Find the certificate file on your computer and open it.</li>
            <li>
              {{ oss[os] }} should ask you to enter a password for the certificate.
              <strong>You can find this password in the email used to get your certificate.</strong>
            </li>
          </template>
          <li>
            You can now
            <a href="https://ssl-test.icij.org">try to access to this page</a>.
          </li>
          <li>
            The first time you try to use the certificate, your browser (Chrome, Firefox, etc) might ask you to confirm
            that you want to use the ICIJ certificate.
          </li>
          <li>If you can see the page, it means you installed your certificate correctly.</li>
        </ol>
        <div class="card bg-primary card-inverse mt-4 text-white">
          <div class="card-body">
            <i class="fa fa-ambulance fa-4x text-white float-right ml-2"></i>
            Thanks for reading this tutorial! ICIJ is committed to making its platforms safer every day in order to
            protect our sources and our partners. If you have issues with the steps above, we encourage you to contact
            us via the
            <a href="https://jira.icij.org/servicedesk/customer/portal/4/group/12" class="text-white">Support Desk</a>.
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
