<script>
import { every, range } from 'lodash'

import XemxForm from '@/components/XemxForm'

export default {
  components: { XemxForm },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    const expiresAt = this.user.expires_at ? new Date(this.user.expires_at) : null
    return {
      selectedDay: expiresAt?.getDate(),
      selectedMonth: expiresAt?.getMonth() + 1,
      selectedYear: expiresAt?.getFullYear()
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    actionUrl() {
      return `${this.root}/update_expiration_date`
    },
    days() {
      return ['', ...range(1, 32)]
    },
    months() {
      const months = range(1, 13).map((value) => {
        const date = new Date(1970, value - 1, 1)
        const formatter = new Intl.DateTimeFormat('en-US', { month: 'long' })
        const text = formatter.format(date)
        return { value, text }
      })
      return ['', ...months]
    },
    years() {
      return ['', ...range(this.startYear, this.endYear + 1)]
    },
    startYear() {
      if (this.selectedYear) {
        return Math.min(this.selectedYear, 2017)
      }
      return 2017
    },
    endYear() {
      const endYear = new Date().getFullYear() + 2
      if (this.selectedYear) {
        return Math.max(this.selectedYear, endYear)
      }
      return endYear
    },
    hasDate() {
      return every([this.selectedDay, this.selectedMonth, this.selectedYear])
    }
  }
}
</script>

<template>
  <xemx-form inline class="mb-2" method="POST" :action="actionUrl">
    <b-form-group class="mr-2" label="Month" label-class="sr-only">
      <b-form-select v-model="selectedMonth" :options="months" name="expires_at(2i)" />
    </b-form-group>

    <b-form-group class="mr-2" label="Year" label-class="sr-only">
      <b-form-select v-model="selectedYear" :options="years" name="expires_at(1i)" />
    </b-form-group>
    <b-form-group class="mr-2" label="Day" label-class="sr-only">
      <b-form-select v-model="selectedDay" :options="days" name="expires_at(3i)" />
    </b-form-group>

    <b-button variant="primary" type="submit">
      <template v-if="user.expires_at && !hasDate"> Unset </template>
      <template v-else> Save </template>
    </b-button>
  </xemx-form>
</template>
