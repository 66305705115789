<script>
import ManageUserUnixGroups from '@/components/ManageUserUnixGroups'

export default {
  name: 'UserSystemsUNIX',
  components: { ManageUserUnixGroups },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    }
  },
  methods: {
    async updatePosixStatus(action) {
      await this.api.updatePosixStatus(this.user.id, action)
      this.reload()
    },
    reload() {
      const redirectPath = `${this.root}#/systems/unix`
      if (window.location.href.endsWith(redirectPath)) {
        window.location.reload()
      } else {
        window.location.replace(redirectPath)
      }
    }
  }
}
</script>

<template>
  <div class="user-systems-unix">
    <h3>UNIX</h3>
    <div class="row">
      <div class="col-12 col-lg-6">
        <b-card no-body class="mb-3" header="Status">
          <b-list-group flush>
            <b-list-group-item class="d-flex align-items-center">
              <template v-if="user.is_posix_account">
                <p class="mb-0 flex-grow-1">
                  <fa icon="check" fixed-width class="text-success mr-1" />
                  Account authorized to access servers.
                </p>
                <b-button size="sm" variant="outline-primary" @click="updatePosixStatus('disable')"> Disable </b-button>
              </template>
              <template v-else>
                <p class="mb-0 flex-grow-1">
                  <fa icon="times" fixed-width class="text-danger mr-1" />
                  Account not authorized to access servers.
                </p>
                <b-button size="sm" variant="outline-primary" @click="updatePosixStatus('enable')"> Enable </b-button>
              </template>
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <manage-user-unix-groups :user="user" />
      </div>
    </div>
  </div>
</template>
