/* eslint no-new: 0 */

import Vue from 'vue'
import $ from 'jquery'

$(function () {
  new Vue({
    el: document.querySelector('.users-form'),
    data() {
      return {
        userIds: []
      }
    }
  })
})
