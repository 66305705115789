import axios from 'axios'
import * as WebAuthnJSON from '@github/webauthn-json/browser-ponyfill'

function getCSRFToken() {
  return document.querySelector('meta[name="csrf-token"]')?.content
}

function safeRedirectTo(redirectTo) {
  if (!redirectTo) {
    return
  }
  if (window.location.href.endsWith(redirectTo)) {
    return window.location.reload()
  }
  window.location.replace(redirectTo)
}

async function credentialCallback({ callbackUrl, body, redirectTo }) {
  const headers = { 'X-CSRF-Token': getCSRFToken() }
  const config = { headers, withCredentials: true }
  try {
    const response = await axios.post(callbackUrl, body, config)
    safeRedirectTo(redirectTo ?? response.data?.redirectTo)
  } catch ({ response }) {
    safeRedirectTo(response.data?.redirectTo)
  }
}

async function createCredentialFromCallback({ callbackUrl, publicKey, redirectTo }) {
  const options = WebAuthnJSON.parseCreationOptionsFromJSON({ publicKey })
  const credential = await WebAuthnJSON.create(options)
  const body = { credential }
  return credentialCallback({ callbackUrl, body, redirectTo })
}

async function getCredential({ publicKey, redirectTo, ...rest } = {}) {
  const callbackUrl = '/users/sign_in/challenge/webauthn'
  const options = WebAuthnJSON.parseRequestOptionsFromJSON({ publicKey })
  const credential = await WebAuthnJSON.get(options)
  const body = { credential, ...rest }
  return credentialCallback({ callbackUrl, body, redirectTo })
}

export { createCredentialFromCallback, getCredential }
