<template>
  <b-card no-body class="manage-user-vpn-access" header="Status">
    <b-list-group flush>
      <b-list-group-item class="d-flex align-items-center">
        <template v-if="user.is_vpn_user">
          <p class="mb-0 flex-grow-1">
            <fa icon="check" fixed-width class="text-success mr-1" />
            Account authorized to use ICIJ VPNs.
          </p>
          <b-button size="sm" variant="outline-primary" @click="updateVpnAccess('delete')"> Disable </b-button>
        </template>
        <template v-else>
          <p class="mb-0 flex-grow-1">
            <fa icon="times" fixed-width class="text-danger mr-1" />
            Account not authorized to use ICIJ VPNs.
          </p>
          <b-button size="sm" variant="outline-primary" @click="updateVpnAccess('add')"> Enable </b-button>
        </template>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
export default {
  name: 'ManageUserVpnAccess',
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    }
  },
  methods: {
    async updateVpnAccess(action) {
      await this.api.updateVpnAccess(this.user.id, action)
      this.reload()
    },
    reload() {
      const redirectPath = `${this.root}#/systems/vpn`
      if (window.location.href.endsWith(redirectPath)) {
        window.location.reload()
      } else {
        window.location.replace(redirectPath)
      }
    }
  }
}
</script>

<style lang="css" scoped></style>
