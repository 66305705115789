<template>
  <xemx-form method="POST" :action="actionUrl" class="onboarding-pgp-upload-button">
    <input type="hidden" name="pgp_key" :value="pgpKey" />
    <onboarding-button type="submit" :disabled="disabled" class="onboarding-pgp-upload-button__button">
      Upload
    </onboarding-button>
  </xemx-form>
</template>
<script>
import OnboardingButton from '@/components/onboarding/OnboardingButton'
import XemxForm from '@/components/XemxForm'

export default {
  name: 'OnboardingPgpUploadButton',
  components: { XemxForm, OnboardingButton },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    pgpKey: {
      type: String,
      required: true
    }
  },
  computed: {
    actionUrl() {
      return '/onboarding/update-pgp-key'
    }
  }
}
</script>
