<script>
export default {
  name: 'MainFooter',
  props: {
    hasRevision: {
      type: Boolean
    },
    revisionShort: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div v-if="hasRevision" class="main-footer">
    <footer class="container text-muted text-right small">
      Version
      <abbr :title="revisionShort" data-toggle="tooltip">{{ revisionShort }}</abbr>
    </footer>
  </div>
</template>
