<template>
  <div class="webauthn-form">
    <div class="form-signin__lead">
      <h2 class="h3">Log in</h2>
      <p class="lead">
        Use one of your security keys to sign on. If it's a USB stick, insert it, and, if necessary, tap it.
      </p>
    </div>
    <div class="form-check form-group">
      <input id="user_remember_me" v-model="remember" type="checkbox" class="form-check-input" name="remember_me" />
      <label for="user_remember_me" class="form-check-label">Remember this device</label>
    </div>
    <div class="pb-5">
      <b-button autofocus variant="primary" block class="text-uppercase font-weight-bold" @click="getCredential">
        <i class="fa fa-key mr-2"></i>
        Use security key
      </b-button>
    </div>
  </div>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { getCredential } from '@/utils/credential'

export default {
  name: 'TwoFactorWebauthnForm',
  inject: ['api'],
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      remember: false
    }
  },
  async mounted() {
    this.fingerprint = await this.getVisitorId()
  },
  methods: {
    async getCredential() {
      const { publicKey, redirectTo } = await this.api.getSecurityKeyOptions()
      const fingerprint = await this.getVisitorId()
      try {
        await getCredential({
          publicKey,
          redirectTo,
          fingerprint,
          remember: this.remember
        })
      } catch (error) {
        console.error(error)
      }
    },
    async getVisitorId() {
      const fp = await FingerprintJS.load()
      const { visitorId } = await fp.get()
      return visitorId
    }
  }
}
</script>
