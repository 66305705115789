<template>
  <xemx-form class="two-factor-email-form" method="POST" action="/users/sign_in/challenge/email">
    <div class="form-signin__lead">
      <h2 class="h3">Log in</h2>
      <p class="lead">To confirm your identity, we will send you an encrypted email.</p>
      <b-btn block variant="primary" class="font-weight-bold" type="submit">
        <i class="fas fa-envelope mr-2"></i>
        Send email
      </b-btn>
    </div>
    <p class="text-muted text-center small">This email will only be valid for 2 hours.</p>
  </xemx-form>
</template>

<script>
import XemxForm from '@/components/XemxForm'

export default {
  name: 'TwoFactorEmailForm',
  components: { XemxForm },
  props: {
    userId: {
      type: String,
      required: true
    }
  }
}
</script>
