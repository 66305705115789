<template>
  <section class="onboarding-step-pgp d-flex flex-column flex-lg-row justify-content-between align-items-center">
    <div class="onboarding-step-pgp__main d-flex flex-column col-lg-9 col-12 mb-4">
      <onboarding-title :step="2"> Create and/or upload your PGP key </onboarding-title>
      <section v-if="notSelectedYet" class="onboarding-step-pgp__main__choice d-flex col-9 col-lg-12 mx-auto">
        <img src="@/assets/onboarding/lock.svg" class="img-fluid mt-auto col-4" alt="Person closing a lock" />
        <div class="col-7">
          <onboarding-info>
            <template #header> Wait! What is a PGP key? </template>
            <template #content>
              <ul>
                <li>A PGP key is a mandatory security feature at ICIJ</li>
                <li>PGP stands for "Pretty Good Privacy"</li>
                <li>It is a "key", a personal long combination of characters</li>
                <li>It allows you to read encrypted emails sent by ICIJ</li>
              </ul>
            </template>
          </onboarding-info>
          <b-form-group
            v-slot="{ ariaDescribedby }"
            label="Do you already have a PGP key?"
            label-class="font-weight-bold"
            class="my-4"
          >
            <b-form-radio-group
              v-model="selected"
              name="know-pgp"
              class="px-4"
              :aria-describedby="ariaDescribedby"
              stacked
            >
              <b-form-radio :value="true" class="my-3">Yes</b-form-radio>
              <b-form-radio :value="false" class="my-3">No or I don't know</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </section>
      <onboarding-upload-pgp-key
        v-else-if="iHaveAPgpKey"
        :user-id="userId"
        :email="email"
        @back-clicked="selected = null"
      />

      <onboarding-create-pgp-key
        v-else-if="iDontHaveAPgpKey"
        :user-id="userId"
        :email="email"
        @back-clicked="selected = null"
      />
    </div>
    <div class="onboarding-step-pgp__side col-6 col-lg-3">
      <onboarding-support />
    </div>
  </section>
</template>
<script>
import OnboardingInfo from '@/components/onboarding/OnboardingInfo'
import OnboardingTitle from '@/components/onboarding/OnboardingTitle'
import OnboardingSupport from '@/components/onboarding/OnboardingSupport'
import OnboardingCreatePgpKey from '@/components/onboarding/OnboardingCreatePgpKey'
import OnboardingUploadPgpKey from '@/components/onboarding/OnboardingUploadPgpKey'

const hashValue = Object.freeze({
  CREATE: 'create',
  UPLOAD: 'upload'
})

export default {
  name: 'OnboardingStepPgp',
  components: {
    OnboardingInfo,
    OnboardingTitle,
    OnboardingSupport,
    OnboardingCreatePgpKey,
    OnboardingUploadPgpKey
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: this.selectedFromHash()
    }
  },
  computed: {
    iHaveAPgpKey() {
      return this.selected === true
    },
    iDontHaveAPgpKey() {
      return this.selected === false
    },
    notSelectedYet() {
      return this.selected === null
    }
  },
  watch: {
    selected(value) {
      const hash = window.location.hash.split('#')[1]
      if (value === true && hash !== hashValue.UPLOAD) {
        window.location.hash = hashValue.UPLOAD
      } else if (value === false && hash !== hashValue.CREATE) {
        window.location.hash = hashValue.CREATE
      } else if (value === null) {
        window.location.hash = ''
      }
    }
  },
  created() {
    window.addEventListener('hashchange', () => {
      const hash = window.location.hash.split('#')[1]
      if (hash === hashValue.UPLOAD && this.selected !== true) {
        this.selected = true
      } else if (hash === hashValue.CREATE && this.selected !== false) {
        this.selected = false
      } else if (hash !== hashValue.CREATE && hash !== hashValue.UPLOAD && this.selected !== null) {
        this.selected = null
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('hashchange')
  },
  methods: {
    selectedFromHash() {
      const hash = window.location.hash.split('#')[1]
      if (hash === hashValue.UPLOAD) {
        return true
      } else if (hash === hashValue.CREATE) {
        return false
      }
      return null
    }
  }
}
</script>
