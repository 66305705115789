<script>
import { cloneDeep } from 'lodash'

import CountrySelect from '@/components/CountrySelect'
import XemxForm from '@/components/XemxForm'

export default {
  name: 'UserProfileEdit',
  components: {
    XemxForm,
    CountrySelect
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: cloneDeep(this.user)
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    updateProfileUrl() {
      return `${this.root}/update_profile`
    }
  }
}
</script>

<template>
  <div class="user-profile-edit">
    <h3>Profile</h3>
    <div class="row">
      <div class="col order-2 mb-2 text-center">
        <b-form-group class="user-profile-edit__picture" label-class="sr-only" label="Profile picture">
          <b-img
            fluid
            class="bg-light user-profile-edit__picture__img"
            rounded="circle"
            height="180"
            width="180"
            :src="user.avatar_url_240"
          />
          <div v-if="userPermissions.update_avatar" class="m-3">
            <b-button
              v-b-tooltip.bottom
              :href="user.avatar_edit_url"
              target="_blank"
              variant="outline-primary"
              size="sm"
              :title="user.avatar_source"
            >
              <fa icon="pen" /> Edit
            </b-button>
          </div>
        </b-form-group>
      </div>
      <xemx-form class="col-8 col-lg-6 mb-2" method="POST" :action="updateProfileUrl">
        <b-form-group label="Username" description="The username cannot be changed.">
          <b-form-input v-model="form.uid" disabled />
        </b-form-group>

        <b-form-group
          label="Name"
          description="The name may appear around ICIJ platforms where you contribute or are mentioned."
        >
          <b-form-input v-model="form.name" name="name" required type="text" />
        </b-form-group>

        <b-form-group label="Organization / Media">
          <b-form-input v-model="form.organization" name="organization" type="text" />
        </b-form-group>

        <b-form-group label="Country">
          <country-select v-model="form.country" name="country" />
        </b-form-group>

        <b-button type="submit" variant="primary"> Save profile </b-button>
      </xemx-form>
    </div>
  </div>
</template>
