<template>
  <b-tooltip :target="target" triggers="hover" placement="bottom" offset="-100">
    {{ label }}
    <div v-if="showEnvTypeBadge">
      <span class="badge badge-warning">{{ envType }}</span>
    </div>
  </b-tooltip>
</template>

<script>
export default {
  name: 'AppNameTooltip',
  props: {
    envType: {
      type: String,
      required: false,
      default: 'production'
    },
    label: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: true
    }
  },
  computed: {
    showEnvTypeBadge() {
      return ['staging', 'ephemeral'].includes(this.envType)
    }
  }
}
</script>
