<script>
export default {
  name: 'PermissionIcon',
  props: {
    permission: {
      type: Object,
      required: true
    },
    iconId: {
      type: String,
      required: true
    }
  },
  computed: {
    classList() {
      return {
        'text-success permission': this.permission.access,
        'text-danger permission': !this.permission.access
      }
    }
  }
}
</script>

<template>
  <span :class="classList">
    <font-awesome-icon :id="iconId" :icon="permission.icon" class="permission__icon" />
    <b-tooltip triggers="hover" placement="bottom" :target="iconId">
      {{ permission.tooltip }}
    </b-tooltip>
  </span>
</template>

<style>
.permission {
  display: block;
  text-align: center;

  &__icon {
    cursor: pointer;
  }
}
</style>
