<script>
export default {
  name: 'UserSignIns',
  filters: {
    formatDatetime(datetime, { dateStyle = 'long', timeStyle = 'short' } = {}) {
      const date = new Date(datetime)
      const options = { dateStyle, timeStyle }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      busy: false,
      signIns: []
    }
  },
  async created() {
    this.busy = true
    this.signIns = await this.api.getSignInUrls(this.user.id)
    this.busy = false
  }
}
</script>

<template>
  <b-overlay class="user-sign-ins" :show="busy" variant="transparent">
    <div class="row mb-2">
      <div v-for="(signIn, index) in signIns" :key="index" class="col-12 col-sm-4 mb-3">
        <b-card
          class="h-100"
          body-class="d-flex align-items-center"
          :border-variant="signIn.is_current ? 'success' : null"
        >
          <fa icon="desktop" size="2x" class="text-muted mr-3" />
          <div>
            <div class="m-0">
              <template v-if="signIn.city || signIn.country">
                {{ [signIn.city, signIn.country].join(', ') }}
              </template>
              <template v-else>
                <em>Unknown location</em>
              </template>
              <div class="text-muted" :title="signIn.created_at | formatDatetime">
                {{
                  signIn.created_at
                    | formatDatetime({
                      dateStyle: 'medium',
                      timeStyle: 'short'
                    })
                }}
              </div>
            </div>
            <b-badge variant="transparent" class="border">
              {{ signIn.ip }}
            </b-badge>
            <b-badge v-if="signIn.is_current" variant="success"> Current session </b-badge>
          </div>
        </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<style scoped>
.user-sign-ins[aria-busy='true'] {
  min-height: 6rem;
}
</style>
