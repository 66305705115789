<template>
  <div class="onboarding-support card p-4 text-center row">
    <b-card-text class="onboarding-support__text">
      <h5 class="onboarding-support__text__title card-title">Need help ?</h5>
      Get in touch with <br />
      <b-avatar size="6rem" :src="avatar" class="my-2"></b-avatar><br />
      <span class="font-weight-bold">{{ name }}</span
      ><br />
      IT specialist<br />
      <a :href="`mailto:${email}`">{{ email }}</a
      ><br />
      <fa icon="globe" fixed-width class="mr-1" />
      speaks English and French
    </b-card-text>
  </div>
</template>
<script>
export default {
  name: 'OnboardingInfo',
  props: {
    name: {
      type: String,
      default: 'Whitney Awanayah'
    },
    email: {
      type: String,
      default: 'wawanayah@icij.org'
    },
    avatar: {
      type: String,
      default: 'https://talk.cloud.icij.org/user_avatar/talk.cloud.icij.org/wawanayah/240/1.png'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utils/variables';

.onboarding-support {
  border: 2px $primary solid;
  border-radius: 0.5em;
  min-width: 220px;
  &__text {
    line-height: 2em;
    &__title {
      color: $primary;
    }
  }
}
</style>
