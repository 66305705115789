<script>
import FormButton from '@/components/FormButton'
import OnboardingProgressBar from '@/components/OnboardingProgressBar'
import { ONBOARDING_STATES } from '@/utils/onboarding'

export default {
  name: 'UserAccessInvitations',
  components: {
    FormButton,
    OnboardingProgressBar
  },
  filters: {
    formatDatetime(datetime, { dateStyle = 'long', timeStyle = 'short' } = {}) {
      try {
        const date = new Date(datetime)
        const options = { dateStyle, timeStyle }
        return new Intl.DateTimeFormat('en-US', options).format(date)
      } catch (_) {
        return datetime
      }
    }
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      magicLink: ''
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    sendInvitationEmailUrl() {
      return `${this.root}/send_invitation_email`
    },
    userIsOnboardingCreated() {
      return this.user.onboarding_state === ONBOARDING_STATES.CREATED
    },
    userIsOnboardingLegacy() {
      return this.user.onboarding_state === ONBOARDING_STATES.LEGACY
    }
  },
  methods: {
    async generateMagicLink() {
      try {
        const { link } = await this.api.generateMagicLink(this.user.id)
        this.magicLink = link
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Unable to generate the magic link',
          variant: 'danger'
        })
      }
    }
  }
}
</script>

<template>
  <div class="user-access-invitations col-12">
    <h3>Invitations</h3>
    <dl v-if="userIsOnboardingLegacy && userPermissions.invite" class="mb-5">
      <dt>Invitation to create an account</dt>

      <dd>
        <template v-if="!user.invitation_sent_at">
          <p class="mb-0">
            <fa icon="times" class="text-danger mr-1" />
            User was created before the onboarding flow existed but was not invited yet.
          </p>

          <p class="text-muted small">
            User must be invited by email first to define a password and start using ICIJ platforms.
          </p>
          <p>
            <form-button :url="sendInvitationEmailUrl" :redirect="root" size="sm" variant="primary">
              <fa icon="paper-plane" class="mr-1" />
              Send invitation
            </form-button>
          </p>
        </template>

        <template v-else>
          <p class="mb-0">
            <fa icon="check" fixed-width class="text-success mr-1" />
            User was invited by email before the onboarding flow existed.
          </p>
          <template v-if="user.invitation_accepted_at">
            <p>
              <fa icon="check" fixed-width class="text-success mr-1" />
              Invitation accepted on
              {{ user.invitation_accepted_at | formatDatetime }}
            </p>
          </template>
          <template v-else>
            <p>
              <fa icon="times" fixed-width class="text-danger mr-1" />
              Invitation not accepted yet
            </p>
            <form-button :url="sendInvitationEmailUrl" :redirect="root" size="sm" variant="primary">
              <i class="fas fa-paper-plane"></i>
              Send invitation again
            </form-button>
          </template>
        </template>
      </dd>
    </dl>

    <onboarding-progress-bar
      v-if="!userIsOnboardingLegacy"
      class="mb-4"
      :onboarding-state="user.onboarding_state"
      :user="user"
    ></onboarding-progress-bar>

    <dl v-if="userIsOnboardingCreated" class="user-access-invitations__magic-link mb-5 pl-0 col-10">
      <dt>Magic link</dt>

      <dd>
        <p class="text-muted small">User will be able to start the onboarding for 24 hours using this link.</p>
        <p>
          <b-button size="sm" variant="primary" @click="generateMagicLink">
            <fa icon="wand-magic-sparkles" class="mr-1" />
            Generate onboarding link
          </b-button>
          <template v-if="magicLink.length > 0">
            <b-form-textarea
              v-model="magicLink"
              class="user-access-invitations__magic-link--textarea text-monospace p-2 mt-4 mb-2"
              max-rows="6"
              name="magicLink"
              rows="3"
              size="sm"
              type="text"
              readonly
              spellcheck="false"
            />
            <haptic-copy type="button" class="btn btn-outline-dark btn-sm mr-2" :text="magicLink" />
          </template>
        </p>
      </dd>
    </dl>
    <p class="user-access-invitations__flow-conversation text-muted">
      An example of onboarding flow conversation is available
      <a href="https://doc.cloud.icij.org/en/technology/workflows/onboarding-flow-conversation">here</a>.
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import '../utils/variables';

.user-access-invitations {
  &__magic-link {
    & &--textarea {
      word-break: break-all;
      background: $input-bg;
    }
  }
}
</style>
