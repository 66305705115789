<template>
  <section class="onboarding-step-enjoy d-flex flex-lg-row flex-column justify-content-between align-items-center">
    <div class="d-flex flex-column col-9">
      <onboarding-title :step="5" class="col-11 mx-auto">
        Success, you are done! Log in and enjoy ICIJ's services
      </onboarding-title>
      <div class="d-flex col-12">
        <div class="d-none d-lg-block col-lg-4">
          <img src="@/assets/onboarding/balloons.svg" alt="balloons" class="img-fluid" />
        </div>
        <div class="d-flex flex-column col-12 col-lg-8">
          <onboarding-info>
            <template #header> What is Account? </template>
            <template #content>
              <ul>
                <li>Account is ICIJ's Single Sign-On service (SSO)</li>
                <li>It allows you to log in to multiple platforms at once</li>
                <li>Connect to one platform and enter the others without logging in again</li>
                <li>
                  To connect to Account, you will enter your username, your password and your 2FA code with your 2FA app
                  on your phone
                </li>
              </ul>
            </template>
          </onboarding-info>
          <onboarding-button icon-right href="/#" class="mx-auto p-3">Go to Account</onboarding-button>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <onboarding-support class="row" />
    </div>
  </section>
</template>
<script>
import OnboardingInfo from '@/components/onboarding/OnboardingInfo'
import OnboardingTitle from '@/components/onboarding/OnboardingTitle'
import OnboardingSupport from '@/components/onboarding/OnboardingSupport'
import OnboardingButton from '@/components/onboarding/OnboardingButton'

export default {
  name: 'OnboardingStepEnjoy',
  components: {
    OnboardingInfo,
    OnboardingTitle,
    OnboardingSupport,
    OnboardingButton
  },
  props: {
    username: {
      type: String,
      required: true
    }
  }
}
</script>
