<template>
  <h1 class="onboarding-title m-4" :class="{ 'onboarding-title--with-step': !!step }" :style="`--step-number:${step}`">
    <slot></slot>
  </h1>
</template>
<script>
export default {
  name: 'OnboardingTitle',
  props: {
    step: {
      type: Number,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utils/variables';

.onboarding-title {
  --counter-position: -14px;
  --counter-size: 1em;
  --title-height: 1.6em;

  line-height: var(--title-height);
  font-size: var(--title-height);

  &--with-step {
    counter-reset: stepper;
    &::before {
      counter-set: stepper var(--step-number);
      content: counter(stepper);
      color: $light;
      background-color: $primary;
      border-radius: 50%;
      line-height: var(--title-height);
      font-size: var(--counter-size);
      height: var(--title-height);
      width: var(--title-height);
      left: var(--counter-position);
      position: relative;
      display: inline-block;
      text-align: center;
      vertical-align: center;
    }
  }
}
</style>
