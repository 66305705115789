<template>
  <form-groups
    label="Edit UNIX groups"
    :user="user"
    :all-groups="allGroups"
    :pre-checked-groups="existingGroups"
    :options-for-select="optionsGroups"
    @updateGroups="updateLdapUnixGroups"
  >
  </form-groups>
</template>

<script>
import FormGroups from '@/components/FormGroups'

export default {
  name: 'ManageUserUnixGroups',
  components: {
    FormGroups
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    allGroups() {
      return this.user.all_posix_groups
    },
    existingGroups() {
      return this.user.posix_groups
    },
    root() {
      return `/users/${this.user.id}`
    },
    optionsGroups() {
      return this.allGroups.map((value) => {
        return { value }
      })
    }
  },
  methods: {
    updateLdapUnixGroups(selected) {
      const current = this.user.posix_groups
      const difference = this.difference(current, selected)
      const toRemove = difference.filter((group) => current.includes(group))
      const toAdd = difference.filter((group) => !current.includes(group))

      return this.makeLdapUnixGroupRequest(toAdd, toRemove)
    },
    difference(first, second) {
      const a = new Set(first)
      const b = new Set(second)

      return [...first.filter((element) => !b.has(element)), ...second.filter((element) => !a.has(element))]
    },
    async makeLdapUnixGroupRequest(toAdd, toRemove) {
      await this.api.updateLDAPUnixGroups(this.user.id, toAdd, toRemove)
      this.reload()
    },
    reload() {
      const redirectPath = `${this.root}#/systems/unix`
      if (window.location.href.endsWith(redirectPath)) {
        window.location.reload()
      } else {
        window.location.replace(redirectPath)
      }
    }
  }
}
</script>
