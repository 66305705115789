<template>
  <div>
    <div v-for="(blockReason, index) in blockReasons" :key="index" class="permissions-caption mb-1">
      <div>
        <font-awesome-icon :class="iconClassList(blockReason)" :icon="getPermissionIcon(blockReason)" />
      </div>
      <div>
        {{ getIconTooltip(blockReason) }}
        <a v-if="appBlocked(blockReason)" :href="editBlockedAppsUrl">Unblock applications here.</a>
        <a v-if="nonPartner(blockReason)" :href="editPartnerStatusUrl">Change partner status here.</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionsCaption',
  props: {
    blockReasons: {
      type: Array,
      required: true
    },
    getPermissionIcon: {
      type: Function,
      required: true
    },
    getIconTooltip: {
      type: Function,
      required: true
    }
  },
  computed: {
    editBlockedAppsUrl() {
      return '#/access/blocked-applications'
    },
    editPartnerStatusUrl() {
      return '#/access/groups'
    }
  },
  methods: {
    appBlocked(blockReason) {
      return blockReason === 'blocked'
    },
    nonPartner(blockReason) {
      return blockReason === 'partners_only'
    },
    notBlocked(blockReason) {
      return blockReason === 'not_blocked'
    },
    iconClassList(blockReason) {
      return this.notBlocked(blockReason) ? 'text-success' : 'text-danger'
    }
  }
}
</script>

<style scoped>
.permissions-caption {
  display: grid;
  grid-template-columns: 2em auto;
  align-items: center;
}
</style>
