export const ONBOARDING_STATES = Object.freeze({
  CREATED: 'created',
  PASSWORD_ADDED: 'password_added',
  PGP_KEY_UPLOADED: 'pgp_key_uploaded',
  ACCOUNT_CONFIRMED: 'account_confirmed',
  TWO_FACTOR_ENABLED: 'two_factor_enabled',
  LEGACY: 'legacy_onboarding'
})

export const ONBOARDING_STEPS = Object.freeze({
  [ONBOARDING_STATES.CREATED]: { label: 'Account created', stepNumber: 0 },
  [ONBOARDING_STATES.PASSWORD_ADDED]: {
    label: 'Password added',
    stepNumber: 1
  },
  [ONBOARDING_STATES.PGP_KEY_UPLOADED]: {
    label: 'PGP uploaded',
    stepNumber: 2
  },
  [ONBOARDING_STATES.ACCOUNT_CONFIRMED]: {
    label: 'Account confirmed',
    stepNumber: 3
  },
  [ONBOARDING_STATES.TWO_FACTOR_ENABLED]: {
    label: '2FA enabled',
    stepNumber: 4
  }
})
