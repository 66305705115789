import JsSHA from 'jssha'

export default class Totp {
  constructor(secret) {
    this.secret = this.base32tohex(Totp.cleanCode(secret))
  }

  dec2hex(s) {
    return (s < 15.5 ? '0' : '') + Math.round(s).toString(16)
  }

  hex2dec(s) {
    return parseInt(s, 16)
  }

  base32tohex(base32) {
    const base32chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567'
    let bits = ''
    let hex = ''

    for (let i = 0; i < base32.length; i++) {
      const val = base32chars.indexOf(base32.charAt(i).toUpperCase())
      bits += this.leftPad(val.toString(2), 5, '0')
    }

    for (let i = 0; i + 4 <= bits.length; i += 4) {
      const chunk = bits.substr(i, 4)
      hex = hex + parseInt(chunk, 2).toString(16)
    }
    return hex
  }

  leftPad(str, len, pad) {
    if (len + 1 >= str.length) {
      str = Array(len + 1 - str.length).join(pad) + str
    }
    return str
  }

  getOtpCode() {
    const epoch = Math.round(new Date().getTime() / 1000.0)
    const time = this.leftPad(this.dec2hex(Math.floor(epoch / 30)), 16, '0')
    const shaObj = new JsSHA('SHA-1', 'HEX')

    shaObj.setHMACKey(this.secret, 'HEX')
    shaObj.update(time)

    const hmac = shaObj.getHMAC('HEX')
    const offset = this.hex2dec(hmac.substring(hmac.length - 1))
    const otp = (this.hex2dec(hmac.substr(offset * 2, 8)) & this.hex2dec('7fffffff')) + ''
    return otp.substr(otp.length - 6, 6)
  }

  static cleanCode(code) {
    return code.replace(/\s/g, '')
  }

  static bootstrap(secret) {
    return new Promise((resolve) => {
      const totp = new Totp(secret)
      resolve(totp)
    })
  }
}
