import 'bootstrap'
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BootstrapVue from 'bootstrap-vue'
import Murmur from '@icij/murmur'
import VueWait from 'vue-wait'

import '@/utils/settings'
import '@/utils/totp'
import '@/users/index'
// Views
import UserShow from '@/views/users/show'
// Components
import BulkImportForm from '@/components/BulkImportForm'
import CertificateForm from '@/components/CertificateForm'
import FlashMessage from '@/components/FlashMessage'
import MainFooter from '@/components/MainFooter'
import ManagePhysicalKeys from '@/components/ManagePhysicalKeys'
import Navbar from '@/components/Navbar'
import TwoFactor from '@/components/TwoFactor'
import TwoFAWarning from '@/components/TwoFAWarning'
import UserSecurity2FAForm from '@/components/UserSecurity2FAForm'
import OnboardingPage from '@/components/onboarding/OnboardingPage'
// Global styles
import '@/application.scss'
// Injected api instance
import { api } from '@/services/api'
import ActivityDatePicker from '@/components/ActivityDatePicker'

library.add(fas)

Vue.config.productionTip = process.env.NODE_ENV === 'development'

const bootstrapVueConfig = {
  BToast: {
    solid: true,
    toaster: 'b-toaster-top-center'
  }
}

Vue.use(BootstrapVue, bootstrapVueConfig)
Vue.use(Murmur)
Vue.use(VueWait)

Vue.component('UserShow', UserShow)
Vue.component('ActivityDatePicker', ActivityDatePicker)
Vue.component('BulkImportForm', BulkImportForm)
Vue.component('CertificateForm', CertificateForm)
Vue.component('FlashMessage', FlashMessage)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('MainFooter', MainFooter)
Vue.component('ManagePhysicalKeys', ManagePhysicalKeys)
Vue.component('Navbar', Navbar)
Vue.component('TwoFAWarning', TwoFAWarning)
Vue.component('TwoFactor', TwoFactor)
Vue.component('UserSecurity2faForm', UserSecurity2FAForm)
Vue.component('OnboardingPage', OnboardingPage)

document.addEventListener('DOMContentLoaded', () => {
  const wait = new VueWait()
  const provide = { api }

  // Creates a Vue instance on every element with the `vue-workspace` class.
  // This is a temporary solution until we migrated all legacy Vue instances
  // to Vue components.
  document.querySelectorAll('.vue-workspace').forEach((el) => new Vue({ el, wait, provide }))
})
