<script>
import SshKeys from '@/components/SshKeys'

export default {
  name: 'UserSystemsSSHKeys',
  components: {
    SshKeys
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div class="user-security-ssh-keys">
    <h3>SSH Keys</h3>
    <ssh-keys :user="user" :user-permissions="userPermissions" />
  </div>
</template>
