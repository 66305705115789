<template>
  <div class="user-show-menu">
    <section v-for="({ section, pages }, i) in sections" :key="i" class="user-show-menu__section">
      <h4 v-if="section !== ''" class="user-show-menu__section__heading">
        {{ section }}
      </h4>
      <ul class="list-unstyled user-show-menu__section__pages">
        <li
          v-for="{ href, icon, name, warning } in pages"
          :key="href"
          class="user-show-menu__section__pages__item"
          :class="{
            'user-show-menu__section__pages__item--active': page.href === href
          }"
        >
          <a :href="`#${href}`" @click="go(href)">
            <font-awesome-icon :icon="icon" class="mr-2" fixed-width size="xs" />
            {{ name }}
            <template v-if="warning">
              <font-awesome-icon
                v-b-tooltip.right="warning"
                class="ml-auto text-warning"
                fixed-width
                icon="warning"
                size="xs"
              />
            </template>
          </a>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { find, groupBy, isFunction, some } from 'lodash'

export default {
  name: 'UserShowMenu',
  model: {
    prop: 'page',
    event: 'input'
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Object,
      required: true
    },
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    authorizedPages() {
      return this.allPages.filter((page) => {
        return some(page.permissions, (permission) => {
          return this.userPermissions[permission]
        })
      })
    },
    allPages() {
      return this.$config
        .scope('users')
        .scope('show')
        .get('pages')
        .map((page) => {
          const warning = isFunction(page.warning) ? page.warning(this.user) : false
          return { ...page, warning }
        })
    },
    sections() {
      const groupedBySection = groupBy(this.authorizedPages, 'section')
      return Object.entries(groupedBySection).reduce((sections, [section, pages]) => {
        sections.push({ section, pages })
        return sections
      }, [])
    }
  },
  methods: {
    go(href) {
      const page = find(this.allPages, { href })
      this.$emit('input', page)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../utils/variables';

.user-show-menu {
  width: 100%;

  &__section {
    padding: $spacer * 0.5;

    &:not(:first-of-type) {
      border-top: 1px solid $border-color;
    }

    &__heading {
      font-size: $font-size-sm;
      font-weight: bold;
      color: $text-muted;
    }

    &__pages {
      margin-bottom: 0;

      &__item {
        a {
          padding: 0 $spacer * 0.5;
          color: inherit;
          display: flex;
          align-items: center;

          .svg-inline--fa {
            color: $text-muted;
          }
        }

        &--active a {
          background: $gray-300;
          border-radius: $border-radius;

          .svg-inline--fa {
            color: $primary;
          }
        }
      }
    }
  }
}
</style>
