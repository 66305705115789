<template>
  <section
    class="onboarding-step-confirm-account d-flex flex-column flex-lg-row justify-content-between align-items-center"
  >
    <div class="d-flex flex-column col-lg-9 col-12">
      <onboarding-title :step="3"> Check your emails to confirm your account </onboarding-title>
      <article class="col-12">
        <div class="col-12 col-md-10 col-lg-8 container">
          <p class="font-weight-bold">
            We sent an encrypted email at your mailbox
            <a href="#">{{ email }}</a>
          </p>
          <p>
            Please decrypt it using your browser extension Mailvelope by following the steps explained at point 5:
            <a href="https://mailvelope.com/en/help#encrypt-decrypt"
              >Encrypting and decrypting an email using your webmail</a
            >
          </p>
          <p>
            In order to decrypt it, you need to click on the email's attachment and enter your
            <abbr title="Pretty Good Privacy">PGP</abbr> password.
          </p>
          <p>If you use Gmail, we recommend <u>NOT</u> allowing Mailvelope's fee-based Gmail API integration.</p>
        </div>

        <div class="d-flex col-12 col-md-10 col-lg-12 container">
          <div class="d-none d-lg-flex">
            <img
              src="@/assets/onboarding/smartphone-woman.svg"
              alt="Woman developer with a smartphone"
              class="img-fluid"
            />
          </div>
          <onboarding-form-one-time-code class="col-lg-8 p-0 p-lg-3" />
        </div>
      </article>
    </div>
    <div class="col-6 col-lg-3">
      <onboarding-support class="row" />
    </div>
  </section>
</template>
<script>
import OnboardingTitle from '@/components/onboarding/OnboardingTitle'
import OnboardingSupport from '@/components/onboarding/OnboardingSupport'
import OnboardingFormOneTimeCode from '@/components/onboarding/OnboardingFormOneTimeCode'

export default {
  name: 'OnboardingStepConfirmAccount',
  components: {
    OnboardingTitle,
    OnboardingSupport,
    OnboardingFormOneTimeCode
  },
  props: {
    email: {
      type: String,
      required: true
    }
  }
}
</script>
