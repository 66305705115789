<script>
import { cloneDeep } from 'lodash'

import XemxForm from '@/components/XemxForm'

export default {
  name: 'UserAccessBlockedApplication',
  components: { XemxForm },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      applications: [],
      groups: [],
      form: cloneDeep(this.user),
      selectedApplications: this.user.blocked_applications.map((blockedApp) => blockedApp.application_id)
    }
  },
  computed: {
    formattedApplications() {
      return this.applications.map((app) => {
        app.nameAndDescription = `
          <strong>${app.name}</strong>
          <p class="small m-0 text-muted">${app.description}</p>
        `
        return app
      })
    },
    root() {
      return `/users/${this.user.id}`
    },
    listGroupsUrl() {
      return `${this.root}/list_groups`
    },
    updateBlockedApplicationsUrl() {
      return `${this.root}/update_blocked_applications`
    },
    checkAllApplications: {
      get: function () {
        return this.applications ? this.selectedApplications.length === this.applications.length : false
      },
      set: function (value) {
        const selectedApplications = []

        if (value) {
          this.applications.forEach((app) => {
            selectedApplications.push(app.id)
          })
        }

        // don't uncheck all the boxes if only one checkbox was unchecked
        if (
          value === false &&
          this.selectedApplications.length > 0 &&
          this.selectedApplications.length < this.applications.length
        ) {
          return
        }

        this.selectedApplications = selectedApplications
      }
    }
  },
  watch: {
    selectedApplications() {
      this.form.blocked_applications = this.selectedApplications.map((id) => ({
        id
      }))
    }
  },
  async created() {
    this.busy = true
    this.applications = await this.api.getOauthApplications()
    this.groups = await this.api.getListGroupsUrl(this.user.id)
    this.busy = false
  }
}
</script>

<template>
  <div class="user-access-blocked-applications">
    <h3>Blocked Applications</h3>
    <p>
      To prevent them from accessing some of these applications, regardless of the
      <a href="#/access/groups">groups</a> they belong to, use this form:
    </p>
    <div class="row">
      <xemx-form class="col-12 col-xl-6 mb-4" method="POST" :action="updateBlockedApplicationsUrl">
        <b-card no-body>
          <b-form-group>
            <div class="card-header">
              <b-form-checkbox v-model="checkAllApplications"> Select all applications </b-form-checkbox>
            </div>
            <b-overlay :show="busy" rounded class="user-access-blocked-applications__checkbox-group-overlay">
              <b-form-checkbox-group
                v-model="selectedApplications"
                aria-label="Blocked applications"
                class="pl-3 pt-3"
                name="applications[]"
                stacked
                html-field="nameAndDescription"
                value-field="id"
                :options="formattedApplications"
              />
            </b-overlay>
          </b-form-group>
        </b-card>
        <b-button type="submit" variant="primary" class="mt-2"> Update </b-button>
      </xemx-form>
      <div class="col">
        <div class="card border-info small">
          <div class="card-header bg-info text-white">
            <h6><fa icon="users" fixed-width class="mr-2"></fa>Memberships</h6>
            Applications access is defined by the group a user belongs to.
          </div>
          <div class="list-group list-group-flush">
            <div v-for="({ name, label, application_names }, index) in groups" :key="index" class="list-group-item">
              <span class="font-weight-bold">{{ label || name }}</span> gives access to:
              <div>
                <fa icon="cube" fixed-width class="mr-1 text-info"></fa>
                <span v-for="application in application_names" :key="application" class="badge badge-info mr-1">
                  {{ application }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-access-blocked-applications {
  &__checkbox-group-overlay .card {
    min-height: 5rem;
  }
}
</style>
