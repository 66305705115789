<script>
import { format } from 'date-fns'

import PgpKeyInput from '@/components/PgpKeyInput'

export default {
  name: 'PgpKey',
  components: { PgpKeyInput },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userPgp: this.user.pgp_key?.slice().trim() || '',
      keyInfoPresent: typeof this.user.pgp_key_info === 'object' && this.user.pgp_key_info !== null
    }
  },
  computed: {
    getIsExpired() {
      return {
        'pgp-key--expired': this.pgpKeyExpired
      }
    },
    pgpKey() {
      return this.user?.pgp_key || ''
    },
    pgpKeyInfo() {
      return this.user.pgp_key_info
    },
    pgpKeyExpired() {
      return this.user.pgp_key_expired
    },
    redirectUrl() {
      return `/users/${this.user.id}/update_pgp_key`
    }
  },
  methods: {
    formatDate(epochTimestamp, options = { short: false }) {
      return format(new Date(epochTimestamp * 1000), `dd MMM yyyy ${options.short ? '' : ', h:mm aaaa'}`)
    }
  }
}
</script>

<template>
  <div class="pgp-key">
    <template v-if="!pgpKey">
      <font-awesome-icon icon="times" fixed-width class="text-danger mr-1" />
      This account does not have a public key yet.
    </template>
    <pgp-key-input :pgp-key="pgpKey" :redirect-url="redirectUrl" :user-id="user.id" haptic-copy>
      <template #header>Edit or drag and drop your PGP key in the input below.</template>
      <template #footer>
        <div v-if="keyInfoPresent" class="card-footer small py-1 px-2" :class="getIsExpired">
          Key
          <abbr :title="pgpKeyInfo.fingerprint" class="font-weight-bold">{{ pgpKeyInfo.key_id }}</abbr>
          <template v-if="pgpKeyInfo.expirationdate.length > 0">
            {{ pgpKeyExpired ? 'expired on' : 'expires on' }}
            <abbr class="font-weight-bold" :title="formatDate(pgpKeyInfo.expirationdate)">
              {{ formatDate(pgpKeyInfo.expirationdate, { short: true }) }}
            </abbr>
          </template>
        </div>
      </template>
    </pgp-key-input>
  </div>
</template>

<style scoped lang="scss">
@import 'node_modules/bootstrap/scss/_functions.scss';
@import '../utils/variables';

.pgp-key {
  .card-footer {
    color: $text-muted;
  }

  &--expired {
    background: theme-color-level('danger', $alert-bg-level);
    color: theme-color-level('danger', $alert-color-level);
  }
}
</style>
