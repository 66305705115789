<script>
import findKey from 'lodash/findKey'

import { ONBOARDING_STEPS } from '@/utils/onboarding'
import XemxForm from '@/components/XemxForm'

export default {
  name: 'OnboardingProgressBar',
  components: { XemxForm },
  props: {
    onboardingState: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    here() {
      return `${this.root}#/email`
    },
    currentStep() {
      return ONBOARDING_STEPS[this.onboardingState].stepNumber
    },
    nextStep() {
      return ONBOARDING_STEPS[this.onboardingState].stepNumber + 1
    },
    onboardingStepsArray() {
      return Object.values(ONBOARDING_STEPS)
    },
    updateOnboardingStateUrl() {
      return `${this.root}/update_onboarding_state`
    }
  },
  methods: {
    findOnboardingState(stepNumber) {
      return findKey(ONBOARDING_STEPS, { stepNumber })
    },
    submitOnboardingStateForm() {
      return () => {
        const onboardingStateForm = this.$refs.onboardingStateForm[0].$el
        // Simply submit the form
        onboardingStateForm?.submit()
      }
    }
  }
}
</script>

<template>
  <dl class="onboarding-progress-bar">
    <dt class="mb-3">Onboarding state</dt>
    <dd class="bg-light py-4 px-3 rounded">
      <ol class="onboarding-progress-bar__list d-flex p-0 m-0">
        <li
          v-for="{ label, stepNumber } in onboardingStepsArray"
          :key="label"
          class="onboarding-progress-bar__list__item"
          :class="{
            'onboarding-progress-bar__list__item--active': currentStep >= stepNumber
          }"
        >
          <xemx-form
            v-if="stepNumber === nextStep"
            ref="onboardingStateForm"
            class="w-100 mt-3 text-center"
            method="POST"
            :action="updateOnboardingStateUrl"
          >
            <input type="hidden" name="onboarding_state" :value="findOnboardingState(stepNumber)" />
            <confirm-button
              class="onboarding-progress-bar__list__item__label btn btn-outline-dark btn-sm border-0"
              label="Skip this step?"
              description="If you do so, please make sure the user setup their account entirely."
              type="button"
              :confirmed="submitOnboardingStateForm()"
            >
              {{ label }}
            </confirm-button>
          </xemx-form>
          <span v-else class="onboarding-progress-bar__list__item__label w-100 mt-3 pt-1 text-center">{{ label }}</span>
        </li>
      </ol>
    </dd>
  </dl>
</template>

<style lang="scss" scoped>
@import '../utils/variables';

.onboarding-progress-bar {
  --counter-size: 1.5em;

  &__list {
    list-style: none;
    counter-reset: onboarding-steps -1;

    &__item {
      counter-increment: onboarding-steps;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      position: relative;
      padding: var(--counter-size) $spacer 0;

      &::before {
        content: counter(onboarding-steps);
        color: $dark;
        background-color: $secondary;
        font-weight: bold;
        border-radius: 50%;
        height: var(--counter-size);
        line-height: var(--counter-size);
        width: var(--counter-size);
        position: absolute;
        display: inline-block;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        text-align: center;
        z-index: 20;
      }

      &:after {
        content: '';
        height: 1px;
        background: $secondary;
        position: absolute;
        left: 50%;
        width: 100%;
        top: calc(var(--counter-size) / 2);
        z-index: 10;
      }

      &--active {
        font-weight: bold;

        &::before {
          background-color: $success;
        }
      }

      &:first-child::before {
        content: '•';
      }

      &:last-child::after {
        display: none;
      }

      &__label {
        font-size: $font-size-base;
      }
    }
  }
}
</style>
