<template>
  <div class="otc">
    <xemx-form
      ref="formEmailConfirmCode"
      class="otc__form"
      name="one-time-code"
      method="POST"
      action="/onboarding/check-confirm-email-code"
    >
      <fieldset class="otc__form__fieldset">
        <legend class="otc__form__fieldset__legend font-weight-bold">Enter the 6-digit code from the email:</legend>
        <digits-input v-model="otc" class="otc__form__fieldset__digits" name="confirm_email_code" />
      </fieldset>

      <p class="text-muted mt-2">If you haven't received any email, please check your Spam folder.</p>
    </xemx-form>
    <p class="text-muted otc__send-new-email d-flex align-items-center">
      <span class="otc__send-new-email__stil-not-received">Still no email received?</span>
      <form-button
        :url="sendNewEmailUrl"
        redirect="/onboarding/confirm-account"
        variant="link"
        class="otc__send-new-email__button"
      >
        Send a new encrypted email
      </form-button>
    </p>
  </div>
</template>

<script>
import { DigitsInput } from '@icij/murmur'

import FormButton from '@/components/FormButton'
import XemxForm from '@/components/XemxForm'

// inspired by https://codepen.io/GeoffreyCrofte/pen/zYrxKRe
export default {
  name: 'OnboardingFormOneTimeCode',
  components: {
    XemxForm,
    FormButton,
    DigitsInput
  },
  data() {
    return {
      otc: ''
    }
  },
  computed: {
    codeIsValid() {
      return String(this.otc).length === 6
    },
    sendNewEmailUrl() {
      return '/onboarding/resend-confirm-email-code'
    }
  },
  watch: {
    otc() {
      if (this.codeIsValid) {
        return this.submit()
      }
    }
  },
  methods: {
    async submit() {
      await this.$nextTick()
      this.$refs.formEmailConfirmCode.$el.submit()
    }
  }
}
</script>

<style lang="scss">
@import '../../utils/variables';

.otc {
  &__form {
    &__fieldset {
      border: 0;
      padding: 0;
      margin: 0;

      &__legend {
        font-size: $font-size-base;
      }

      &__digits {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

input.digits-input__container__input {
  width: 1em;
  height: 1.75em !important;
  margin: 0.1em !important;
  border: none;
  background-color: $gray-400;
  color: black;
}
</style>
