<template>
  <div>
    <input v-if="!isAll" type="hidden" name="activity_gauge_for_ransack" :value="queryParam" />
    <div class="d-flex justify-content-between flex-wrap">
      <label class="small">Filter by activity:</label>
      <b-form-radio-group
        id="activeness-group"
        class="flex-grow-0 flex-md-grow-1 flex-lg-grow-0 flex-grow-0 flex-md-grow-1 flex-lg-grow-0 btn-group-toggle btn-group btn-group-sm bv-no-focus-ring"
      >
        <label
          v-for="act in activenessOptions"
          :key="act"
          class="btn btn-sm"
          :class="act === activeness ? 'btn-dark' : 'btn-outline-dark'"
          @click="() => updateActive(act)"
        >
          <input id="activeness-group" type="radio" class="" :value="act" />{{ capitalizeText(act) }}</label
        >
      </b-form-radio-group>
    </div>

    <date-picker
      ref="calendar"
      v-model="pickedRange"
      timezone="UTC"
      is-range
      color="gray"
      :max-date="maxDate"
      :min-date="minDate"
      :input-debounce="500"
      :masks="{ input: 'YYYY-MM-DD' }"
      :popover="{ visibility: 'click' }"
    >
      <template #default="{ inputValue, inputEvents }">
        <b-input-group class="justify-content-between pt-1 input-group-sm" inline>
          <input
            :value="inputValue.start"
            class="col-5 col-md-10 col-lg-5 form-control"
            :disabled="isAll"
            v-on="inputEvents.start"
          />
          <fa fixed-width icon="arrow-right" class="fa-xs align-self-center" />
          <input
            :value="inputValue.end"
            class="col-5 col-md-10 col-lg-5 form-control"
            :disabled="isAll"
            v-on="inputEvents.end"
          />
        </b-input-group>
      </template>
      <template #footer>
        <div class="d-flex justify-content-around calendar-footer">
          <b-btn variant="info" class="btn-sm m-1" @click="moveToday">Today</b-btn>
          <b-btn
            v-for="cRange in ranges"
            :key="cRange.key"
            :variant="cRange.key === selected ? 'dark' : 'outline-dark'"
            class="btn-sm mr-1 my-1"
            type="button"
            @click="setRange(cRange.range)"
          >
            {{ cRange.text }}
          </b-btn>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'
import { subMonths } from 'date-fns'
import { capitalize, trim } from 'lodash'

const ACTIVENESS = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

const RANGE = {
  ALL: 'all',
  ONE: 'one',
  THREE: 'three',
  YEAR: 'year'
}
const date = new Date()
const maxDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 12, 0, 0))
const minDate = new Date(Date.UTC(2015, 0, 1, 12, 0, 0))

const RANGE_OPTIONS = {
  [RANGE.ALL]: {
    key: RANGE.ALL,
    text: 'All',
    range: {
      start: minDate,
      end: maxDate
    }
  },
  [RANGE.ONE]: {
    key: RANGE.ONE,
    text: 'Last month',
    range: {
      start: subMonths(maxDate, 1),
      end: maxDate
    }
  },
  [RANGE.THREE]: {
    key: RANGE.THREE,
    text: '3 months',
    range: {
      start: subMonths(maxDate, 3),
      end: maxDate
    }
  },
  [RANGE.YEAR]: {
    key: RANGE.YEAR,
    text: '1 year',
    range: {
      start: subMonths(maxDate, 12),
      end: maxDate
    }
  }
}
const defaultRange = {
  start: minDate,
  end: maxDate
}
export default {
  name: 'ActivityDatePicker',
  components: {
    DatePicker
  },
  props: {
    value: { type: String, default: null }
  },
  data() {
    return {
      activeness: ACTIVENESS.ALL,
      pickedRange: defaultRange,
      ranges: RANGE_OPTIONS,
      activenessOptions: ACTIVENESS,
      minDate,
      maxDate
    }
  },
  computed: {
    range() {
      return this.value?.trim()
    },
    hasRange() {
      return !!this.range?.length
    },
    rangeBounds() {
      if (!this.hasRange) {
        return []
      }
      return trim(this.range, '-').split('|')
    },
    isRangeInactive() {
      return this.hasRange && this.rangeBounds[0] === '-'
    },
    isAll() {
      return this.activeness === ACTIVENESS.ALL
    },
    getPickedStartShort() {
      return this.getShortDate(this.pickedRange.start)
    },
    getPickedEndShort() {
      return this.getShortDate(this.pickedRange.end)
    },
    queryParam() {
      if (this.isAll) {
        return null
      }
      let queryRange = ''
      if (this.activeness === this.activenessOptions.INACTIVE) {
        queryRange += '-'
      }
      const start = this.getPickedStartShort
      const end = this.getPickedEndShort
      queryRange += `${start}|${end}`
      return queryRange
    },
    selected() {
      let selected = null
      selected = this.isEqualToPickedRange(this.ranges[RANGE.ONE].range) ? RANGE.ONE : selected
      selected = this.isEqualToPickedRange(this.ranges[RANGE.THREE].range) ? RANGE.THREE : selected
      selected = this.isEqualToPickedRange(this.ranges[RANGE.ALL].range) ? RANGE.ALL : selected
      selected = this.isEqualToPickedRange(this.ranges[RANGE.YEAR].range) ? RANGE.YEAR : selected
      return selected
    }
  },
  watch: {
    async pickedRange() {
      await this.$nextTick()
      this.$el.closest('form').submit()
    }
  },
  created() {
    this.processQueryParams()
  },
  methods: {
    async updateActive(value) {
      this.activeness = value
      await this.$nextTick()
      this.$el.closest('form').submit()
    },
    processQueryParams() {
      if (this.hasRange) {
        if (this.isRangeInactive) {
          this.activeness = ACTIVENESS.INACTIVE
        } else {
          this.activeness = ACTIVENESS.ACTIVE
        }
        const [start, end] = this.rangeBounds
        this.pickedRange.start = new Date(start)
        this.pickedRange.end = new Date(end)
      } else {
        this.activeness = ACTIVENESS.ALL
      }
    },
    move() {
      return this.$refs.calendar.move(this.pickedRange.start)
    },
    moveToday() {
      return this.$refs.calendar.move(maxDate)
    },
    getShortDate(date) {
      return date.toISOString().split('T')[0]
    },
    isEqualToPickedRange(range) {
      return (
        this.getPickedStartShort === this.getShortDate(range.start) &&
        this.getPickedEndShort === this.getShortDate(range.end)
      )
    },
    capitalizeText(value) {
      return capitalize(value)
    },
    async setRange(value) {
      if (!value) return
      this.pickedRange = value
    }
  }
}
</script>
<style lang="scss">
.calendar-footer {
  margin-top: -10px;
  .btn-group-xs {
    height: 1.4rem;
  }
  .btn-xs {
    padding: 0.1rem 0.6rem;
    font-size: 0.875rem;
    line-height: 1.2;
  }
}
</style>
