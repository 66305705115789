<template>
  <div class="two-factor">
    <two-factor-otp-form v-if="isOtp" :user-id="userId" />
    <two-factor-webauthn-form v-else-if="isWebauthn" :user-id="userId" />
    <two-factor-email-form v-else :user-id="userId" />
    <div v-if="hasMultipleSchemes" class="float-right pt-3 pb-5 pr-2">
      <a href="/users/sign_in/challenge/switch"> Use another method </a>
    </div>
  </div>
</template>

<script>
import TwoFactorEmailForm from '@/components/TwoFactorEmailForm'
import TwoFactorWebauthnForm from '@/components/TwoFactorWebauthnForm'
import TwoFactorOtpForm from '@/components/TwoFactorOtpForm'

const SCHEMES = Object.freeze({
  TOTP: 'totp',
  WEBAUTHN: 'webauthn'
})

export default {
  name: 'TwoFactor',
  components: {
    TwoFactorEmailForm,
    TwoFactorOtpForm,
    TwoFactorWebauthnForm
  },
  props: {
    availableSchemeTypes: {
      type: Array,
      default: () => []
    },
    preferedSchemeType: {
      type: String,
      default: null
    },
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedScheme: null
    }
  },
  computed: {
    isOtp() {
      return this.selectedScheme === SCHEMES.TOTP
    },
    isWebauthn() {
      return this.selectedScheme === SCHEMES.WEBAUTHN
    },
    hasMultipleSchemes() {
      return this.availableSchemeTypes.length > 1
    }
  },
  mounted() {
    this.selectedScheme = this.preferedSchemeType ?? this.availableSchemeTypes[0]
  }
}
</script>
